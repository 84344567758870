/* PortfolioCard.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');


.portfolio-full-page-overlay {
position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    /* Changed to black for contrast */
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    /* Text color white for contrast */
    font-size: 2rem;
    z-index: 1000;
    /* Ensure it's very high to sit above other content */
    flex-direction: column;
}




.portfolio-analytics-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    background-color: #ffffff;
    color: #333;
    font-family: 'Inter', sans-serif;
    padding-top: 2vh;
    /* Reduced padding to move cards up */
}

.portfolio-content {
    width: 70%;
    margin-top: 12vh;
    
}

.portfolio-cards-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 1vh;
    min-height: 20vh;
    width: 100%;
    gap: 1vh;
    /* Adjust gap to reduce space between cards */
    flex-wrap: wrap;
    /* Wrap cards on smaller screens */
}

.portfolio-main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin: 1% 1%;
    /* Reduced margin around the main content */
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: visible;
    height: auto;
    /* Adjusted height to auto to contain content properly */
    font-size: 14px;
}

.positive {
    color: #4CAF50;
}

.negative {
    color: #F44336;
}

/* If you have specific styles for .portfolio-card (individual card styling), 
   make sure to include them as well to control their spacing and sizing. */


   .coming-soon-modal {
       display: flex;
       flex-direction: column;
       align-items: center;
       padding: 20px;
       border-radius: 8px;
       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
       background-color: #000000;
       font-size: 15px;
       color: #ffffff;
   }


 .modal-logo {
       max-width: 100px;
       /* Adjust based on your preference */
       margin-bottom: 20px;
       /* Space between logo and text */
   }