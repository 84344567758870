.token-overview {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 43%;
    max-width: 100rem;
    margin-top: 0%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0%;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    /* Updated to use variable */
    overflow: auto;
    height: auto;
    top: 3vh;
    right: 3.4%;
}



.overview-title {
    font-family: 'Inter', sans-serif;
    color: var(--text-color);
    font-size: 15px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.overview-item {
    margin-bottom: 12px;
}

.overview-label {
    display: block;
    /* Makes label take the full width */
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: bold;
}

.overview-data {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: var(--text-color);
}




/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .token-overview {
        width: 89%; /* Full width for better utilization of space */
        top: 0; /* Reset top positioning */
        right: 0; /* Reset right positioning */
        margin-top: 10px; /* Provide some top margin */
        margin-right: 50px;
    }

    .overview-title,
    .overview-label,
    .overview-data {
        font-size: 11px; /* Adjust font size for better readability */
        word-wrap: break-word; /* Ensure long words can break and wrap to the next line */
        white-space: normal; /* Override any previous white-space settings that prevent wrapping */
    }

    .overview-item {
        margin-bottom: 10px; /* Adjust bottom margin for spacing */
    }
}

