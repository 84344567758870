.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

}



.modal-content {
    background: var(--component-background-color);
    padding: 20px;
    border-radius: 5px;
    max-height: 70vh;
    overflow: auto;
    max-width: 600px;
    width: 90%;
        
}

.modal-close-button {
    float: right;
    border: none;
    background: none;
    cursor: pointer;
    
}



.domain-names-list {
    padding: 10px;
  
}


.wallet-info-domain-name-modal {
    display: inline-block;
    font-size: 12px;
    margin-top: 9px;
    padding: 4px 8px;
    background: var(--component-background-color);
    color: var(--text-color);
    border: 1px solid #0000FF;
    border-radius: 10px;
    margin-right: 5px;
    cursor: pointer;
   
}


.total-domains-count {
    margin-top: 0;
    margin-bottom: 16px;
    padding-left: 16px;
    font-size: 12px;
    font-weight: bold;
    color: var(--text-color);

   
}

.wallet-info-domain-name-modal:hover {
    background: var(--component-background-color);
    /* Light grey background on hover */
}