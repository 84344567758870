/* UsernameLink.css */
.username-link-style:link,
.username-link-style:visited,
.username-link-style:hover,
.username-link-style:active {
    color: #176ab3;
    font-weight: bold;
}

.username-link-style {
    color: #176ab3;
    font-weight: bold;
    text-decoration: none;
    overflow-wrap: break-word;
    display: inline;
    word-break: break-all;
    font-size: 12px;
}