.charting-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 65%;
    max-width: 100rem;
    margin-top: -1.5%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2%;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: hidden;
    height: 43vh;
    left: -11.5%;
    
}
