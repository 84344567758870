/* WalletTransactionDetailsComponent.css */

.wallet-transaction-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 81.5%;
    max-width: 100rem;
    margin-right: auto;
    margin-left: 140px;
    margin-bottom: 2%;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    overflow: auto;
    min-height: 20vh;
    top: 2vh;
    margin-top: 10px;
    margin-left: 9.5vw;
}






.wallet-header-row,
.wallet-content-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    /* 6 equal columns */
    gap: 10px;
    /* Adjust gap between columns */
    width: 100%;
}

.wallet-header-row {
    font-weight: bold;
    font-size: 10px;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 0.5rem;
    
}



.wallet-content-row {
    font-size: 12px;
    border-bottom: 1px solid #b0afaf57;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 12px;
    align-items: center;
}

.wallet-no-data {
    grid-column: 1 / -1;
    /* Span across all columns */
    text-align: center;
    font-size: 10px;
}


.wallet-link-container {
    display: flex;
    align-items: center;
    /* Align vertically */
    justify-content: start;
    /* Align horizontally to the start */
}



.icon-inline {
    margin-right: 8px;
    width: 18px;
    height: auto;
}



.amount-with-logo {
    display: flex;
    align-items: center;
}


.tx-panel-token-logo {
    width: 20px;
    border-radius: 50%;
    height: 20px;
    object-fit: contain;
    margin-right: 5px;
}


.wallet-amount-column {
    display: flex;
    align-items: center;
    
}



.highlight-incoming-amount {
    display: inline-flex;
    align-items: center;
    padding: 3px;
    border-radius: 8px;
    background-color: rgb(236, 245, 240);
    /* Ensure there are no margins causing extra space */
    margin: 0;
    /* Fit the content even tighter */
    width: fit-content;
    color: var(--text-color);
    


}



/* Styles for the default logo */
.default-logo {
    background: var(--component-background-color);
    /* Light gray background for visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    /* Adjust as needed */
    color: var(--text-color);
    /* Dark gray text */
}


.tx-panel-token-symbol {
    margin-left: 5px;
    /* Space between the amount and the symbol */
    font-size: 12px;
    /* Match the font size of the amount */
    color: var(--text-color);
    /* Set a suitable text color */
    font-weight: normal;
    /* Adjust the font weight as needed */
}



.burn-address {
    display: inline-block;
    font-size: 12px;
    margin-top: 5px;
    padding: 4px 8px;
    background-color: #fdeaea;
    /* Light red background */
    color: #000000;
    border: 0.4px solid #8e1212;
    /* Deep dark red border */
    border-radius: 10px;
    margin-right: 5px;
    cursor: pointer;
    width: 11vh;
    text-align: center;

}


.wallet-tx-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    width: 100%;
    
}



/* Media query for mobile devices */
@media (max-width: 768px) {
    .wallet-transaction-details {
        /* Full width for mobile view */
        width: 85%;
        /* Adjust padding for smaller screens */
        padding: 0.5rem;
        /* Center the container */
        margin-left: auto;
        margin-right: auto;
        /* Reduce the margin to fit the screen */
        margin-top: 10px;
        /* Remove specified left margin, use percentage for responsiveness */
        margin-left: 5%;
    }

    /* Adjusting grid layout for smaller screens */
    .wallet-header-row,
    .wallet-content-row {
        /* Stack the grid items vertically on small screens */
        grid-template-columns: repeat(2, 1fr);
        /* Show less information or re-arrange to fit two columns */
    }

    .wallet-content-row {
        /* Adjust font size for readability on small screens */
        font-size: 12px;
    }

    .wallet-no-data {
        /* Ensure the no-data message spans across both columns */
        grid-column: 1 / -1;
    }

   
 

    .icon-inline,
    .tx-panel-token-logo,
    .default-logo {
        /* Adjust icon sizes as necessary for mobile view */
        width: 15px;
        height: auto;
    }

    .burn-address {
        /* Ensure the burn address doesn't overflow the screen */
        width: auto;
        /* Allow text to wrap if necessary */
        white-space: normal;
    }

    .wallet-tx-loading-container {
        /* Make sure the loading container fits the screen */
        min-height: 100px;
    }
}
