/* InsightOverlay.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

.insight-full-page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    /* Changed to black for contrast */
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    /* Text color white for contrast */
    font-size: 2rem;
    z-index: 1000;
    /* Ensure it's very high to sit above other content */
    flex-direction: column;
    display: none;
  
   
}

.insight-coming-soon-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #000000;
    /* Modal background black */
    font-size: 15px;
    color: #ffffff;
    /* Text color white */
    display: none;
 
   
}

.insight-modal-logo {
    max-width: 100px;
    margin-bottom: 20px;
}



.intro-modal-insight-content-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    display: none;
 
    
}


.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* Set to the full height of the viewport */
    overflow: hidden;
}



.insight-main-content {
    display: flex;
    flex-grow: 1;
}

.chart-pane {
    flex: 6.5;
    /* Or 7 if you prefer 70% width */
    position: relative;
    /* For absolute positioning of the toggle button */
    border-right: 1px solid #cccccc20;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.chart-pane.collapsed {
    flex: 0;
    /* Collapsed chart pane should not take any space */
}

.chat-pane {
    flex: 3.5;
    /* Or 3 if you prefer 30% width */
  
    transition: flex 0.3s;
    /* Smooth transition for collapsing and expanding */
    position: relative;
}

.chat-pane.expanded {
    flex: 10;
    /* When expanded, chat pane takes more space */
}

.toggle-chart-pane {
    position: absolute;
    top: 50%;
    right: -20px;
    /* Adjust as needed to place the toggle button correctly */
    transform: translateY(-50%);

    cursor: pointer;
    border: none;
    /* Removes default button border */
    background-color: #fff;
    /* Set to your preferred button color */
    border-radius: 50%;
    /* Makes the button circular */
    width: 25px;
    /* Set the size of the button */
    height: 25px;
    /* Set the size of the button */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Adds some shadow */
    transition: transform 0.3s;
    right: -1.5%;
    display: none;
    
}





.expand-button {
    position: absolute;
    right: 3%;
    /* Adjust this value to position the button correctly when collapsed */
    top: 50%;
    /* Center it vertically relative to its container */
    transform: translateY(-50%);
    /* Add any additional styles to make the button visually consistent */
    
}



.wallet-connect-overlay {
    position: absolute;
    top: 0;
    left: 14%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    text-align: center;
    /* Ensure it's above the chat panel content */
    max-width: 70%;
    font-size: 13px;
    
}

.wallet-connect-overlay-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    /* semi-transparent overlay */
    filter: blur(8px);
    /* Apply blur to the background */
}


.mobile-chat-toggle-button {
    display: none;
    /* Hidden by default */
    /* Mobile specific styles will be added inside the media query below */
}



/* Responsive adjustments */
@media (max-width: 576px) {
    /* Full page overlay adjustments omitted for brevity */

    .dashboard-container {
        flex-direction: column;
        /* Keep column direction */
        overflow: hidden;
      
    }


        

    /* Initially, the chart takes full screen */
    .chart-pane {
        width: 94%;
        /* Full width */
        height: 100vh;
        /* Full viewport height */
        display: flex;
        /* Use flex layout */
        justify-content: center;
        /* Center content horizontally */
        align-items: center;
        /* Center content vertically */
        left: -2%;
        position: absolute;
        bottom: 10%;
        z-index: 0;
        border-right: none;
        
    }

    /* The chat pane is initially not displayed */
    .chat-pane {
        display: none;
        /* Hidden by default */
        width: 100%;
        /* Full width */
        height: 100vh;
        /* Full viewport height */
        position: fixed;
        /* Position it on top of the chart pane */
        top: 0;
        left: 0;
        z-index: 5;
        /* Above the chart pane */
        
    }

    /* When the chart is collapsed, the chat pane takes over */
    .chart-pane.collapsed {
        display: none;
        /* Hide chart pane */
    }

    .chat-pane.expanded {
        display: flex;
        /* Show chat pane */
    }

   
        .toggle-chart-pane {
            display: flex;
            /* Ensure the button is visible */
            position: fixed;
            /* Keep it fixed during scrolling */
            bottom: 20px;
            /* Position at the bottom */
            right: 20px;
            /* Position to the right */
            z-index: 100;
            /* Make sure it's above other elements */
            background-color: #333;
            /* Dark background for visibility */
            color: white;
            /* White text/icon */
            border-radius: 50%;
            /* Circular button */
            width: 50px;
            /* Sufficient size for easy tapping */
            height: 50px;
            /* Same as width for circle shape */
            justify-content: center;
            /* Center the icon/text horizontally */
            align-items: center;
            /* Center the icon/text vertically */
            border: none;
            /* No border */
            cursor: pointer;
            /* Pointer cursor on hover */
        }

    /* Styles for wallet connect overlay omitted for brevity */


    
}