/* TokenCards.css */
.token-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 180px;
    /* Adjust width as needed */   
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #1a1a1a;
    /* Dark background for the card */
    color: #ffffff;
    /* White text color */
    font-family: 'Arial', sans-serif;
    height: 70px;
}

.token-card h2 {
    font-size: 12px;
    /* Adjust the font size for the title */
    margin-bottom: 0.5rem;
    /* Space between title and value */
}

.token-card-value {
    font-size: 16px;
    /* Adjust the font size for the value */
    font-weight: bold;
}



@media (max-width: 768px) {
    .token-card {
        width: 85%;
        /* Full width to accommodate smaller screens */
        margin: 0.5rem 0;
        /* Add some vertical margin for separation */
        height: auto;
        /* Let the height grow as needed */
        padding: 1rem;
        /* Increase padding for touch targets */
    }

    .token-card h2 {
        font-size: 1rem;
        /* Increase the font size for readability */
        margin-bottom: 0.5rem;
        /* Maintain space between title and value */
    }

    .token-card-value {
        font-size: 1.2rem;
        /* Slightly increase the font size for value */
    }
}