
.mainTxDetailsCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Consider changing to 'center' if you want the items centered within the card */
    padding: 1rem;
    width: 70%;
    max-width: 100rem;
    margin-left: auto;  /* Centering horizontally */
    margin-right: auto; /* Centering horizontally */
    margin-bottom: 0px;
    margin-top: 19vh; /* Adjust top margin as needed */
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    overflow: auto;
    min-height: auto;
    position: relative; /* No need for top/right when using auto margins for centering */
}



.TxPageBlurred {
    filter: blur(5px);
    /* Adjust the blur value as needed */
    pointer-events: none;
    /* Optional: Disable interaction with the blurred elements */
}





@media (max-width: 768px) {
    .mainTxDetailsCard {
        width: 82%; /* Take full width to utilize available screen space */
       
    }

}
