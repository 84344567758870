.user-sol-balance {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    border-radius: 10px;
    padding: 4px 12px;
    font-size: 12px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 0px;
    margin-bottom: 2px;
    position: relative;
    left: 45px;
    left: 5.5%;
    height: 25px;
    cursor: pointer;
    z-index: 1;
}


.balances-popout-modal {
    position: absolute;
    z-index: 10;
    top: 91%;
    width: 20vh;
    left: 47vw;
    right: 0;
    display: flex;
    flex-direction: column;
    background: var(--general-background-color);
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    transition: max-height 0.4s ease-in-out;
    height: 150px;
    /* Adjusted for animation */
    overflow: hidden;
    padding: 0 12px;
    /* padding for animation */
    font-family: 'Inter', sans-serif;
   
    
}

.balances-header {
    
    border-bottom: 1px solid #91919151;
    font-size: 12px;
}

.balances-content {
    padding: 12px;
    font-size: 11px;
}

/* When modal is closed */
.balances-popout-modal.closed {
    max-height: 0;
    padding: 0;
}

/* Add transitions for padding and max-height for the opening effect */
.balances-popout-modal,
.balances-popout-modal.closed {
    transition: max-height 0.4s, padding 0.4s;
}



@media (max-width: 576px) {
    .user-sol-balance {
        background: none;
        /* No background color for the container */
    }

    .user-sol-balance p,
    .user-sol-balance p strong {
        font-size: 11px;
        /* Set the font size to 10px for both p and strong */
        line-height: 1.2;
        /* Adjust line height to ensure readability at smaller sizes */
       

    }
}