.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: var(--general-background-color);
    /* Define or replace with actual color */
    overflow: auto;
}

.content-container {
    display: flex;
    flex: 1;
}

.create-tokens-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
h2 {
    color: var(--text-color);
    /* Dark grey color for the heading */
    margin-bottom: 20px;
    /* Adds space below the heading */
}