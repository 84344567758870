.token-info-panel {
    position: relative;
    display: flex;
    flex-direction: row; 
    align-items: center; /* Align content to the start */
    padding: 1rem; /* Use rem for consistent padding */
    width: 129vh;
    max-width: 100rem;
    margin-top: -5%; /* Move it higher up */
    margin-right: auto; /* Keep it aligned to the right */
    margin-left: auto; /* Maintain centered alignment from the left */
    margin-bottom: 2%; /* Adjusted for less vertical space */
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: hidden; /* Prevents overflow */
    height: 2vh; /* Height auto to contain content */
    /* Additional position adjustments if needed */
    left: -11.5%; /* Push it to the right */
}


.token-info-panel .token-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: -30px;
    align-self: center;
    
}

.token-info {
    display: flex;
    justify-content: space-around; /* Evenly space content */
    width: 30%;
    
}

.token-info-item {
    display: flex;
    flex-direction: column; /* Stack item label and value */
    justify-content: center; /* Center content for each item */
    align-items: center;
    flex: 1; /* Flex grow to fill available space */
    padding: 0.5rem; /* Consistent padding around items */
}




    /* Styling for headings and paragraphs inside token-info items */
    .token-info-item h1,
    .token-info-item p {
        font-size: 14px; /* Adjust as needed, made consistent for a cleaner look */
        margin: 0; /* Remove margin to avoid unnecessary spacing */
    }

