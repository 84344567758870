.headerbar-search-container {
    position: relative;
    margin-top: 1px;
    right: 1vh;
    left: 11vh;
    /* Adjust left and right margins as needed */
}

.searchbar-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 10px;
    border-bottom: 1px solid #ddd;
    color: black;
    font-weight: bold;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
}

.searchbar-heading {
    flex: 1;
    /* Each header item takes equal space by default */
}





/* Adjust specific headers with different flex values */
.searchbar-heading.token-name {
    flex: 3;
    display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9px 10px;
        color: black;
        font-weight: bold;
        font-size: 12px;
        font-family: 'Inter', sans-serif;
}

/* Give more space to Token name */
.searchbar-heading.symbol {
    flex: 2;
    
}

/* Adjust as needed */
.searchbar-heading.price {
    flex: 2;
}

/* Adjust as needed */
.searchbar-heading.volume {
    flex: 2;
}

/* Adjust as needed */
.searchbar-heading.change {
    flex: 1;
}



.headerbar-search-input {
    width: 65vh;
    height: 28px;
    padding-right: 1%;
    box-sizing: border-box;
    border: none;
    font-family: 'Inter', sans-serif;
    background-color: white;
    border-radius: 5px;
    padding-left: 40px;
    border: 0.1px solid lightgrey;
}

    .headerbar-search-input:focus {
        outline: none;
        border: 2px solid lightgrey;
    }

.searchbar-dropdown {
    position: absolute;
    overflow: auto;
    top: 80%;
    width: 65vh;
    height: 240px;
    background-color: #ffffff;
    padding: 0px;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    z-index: 2;
    display: flex;
        flex-direction: column;
        padding: 0;
        /* Remove padding to align with the headers */
}




.headerbar-search-icon {
    position: absolute;
    left: 0px;
    top: 28%;
    transform: translateY(-50%);
    width: 23%;
    height: auto;
}



.searchbar-session-item {
    display: flex;
    justify-content: space-between; /* This will evenly space the headings */
    align-items: center;
    padding: 5px 10px; /* Adjust padding as needed */
    font-size: 14px;
    border-bottom: none; /* Optional border for separation */
    color: #747474;
    font-size: 11px;
    font-family: 'Inter', sans-serif;
}



.searchbar-session-item:hover {
    background-color: #f0f0f0;
    border-radius: 6px;
    cursor: pointer;
}

.searchbar-session-item span {
    flex: 1;
    /* Default flex value */
}

/* Assign flex values similar to the header */
.searchbar-session-item .token-name {
    flex: 3;
    display: flex;
        justify-content: space-between;
        /* This will evenly space the headings */
        align-items: center;
        padding: 5px 0px;
        /* Adjust padding as needed */
        
        border-bottom: none;
        /* Optional border for separation */
        color: #747474;
        font-size: 12px;
        font-family: 'Inter', sans-serif;
}

.searchbar-session-item .symbol {
    flex: 2;
}

.searchbar-session-item .price {
    flex: 2;
    margin-right: 3%;
    
    
}

/* Align price to the right */
.searchbar-session-item .volume {
    flex: 2;
}

.searchbar-session-item .change {
    flex: 1;
}





.searchbar-heading {
    flex: 1; /* This ensures each heading takes equal width */
    text-align: left; /* Center the text in each heading */
    font-family: 'Inter', sans-serif;
}



.searchbar-session-item>span {
    flex: 1;
    text-align: left;
    /* Align text to the left */
    padding-left: 0px;
    /* Align with the headers */
}

.search-icon {
    position: absolute;
    left: 10px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
}

.token-logo {
    width: 20px;
    /* Adjust size as needed */
    height: 20px;
    /* Adjust size as needed */
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
