/* TokenLink.css */
.TokenLinkStyle:link,
.TokenLinkStyle:visited,
.TokenLinkStyle:hover,
.TokenLinkStyle:active {
    color: #176ab3;
    font-weight: bold;
}

.TokenLinkStyle {
    color: #176ab3;
    font-weight: bold;
    text-decoration: none;
    overflow-wrap: break-word;
    display: inline;
    word-break: break-all;
    font-size: 12px;
}