.TxAndWalletLink:link,
.TxAndWalletLink:visited,
.TxAndWalletLink:hover,
.TxAndWalletLink:active {
  color: #176ab3;
  font-weight: bold;
  
}

/* WalletAndTxLink.css */
.TxAndWalletLink {
  color: #176ab3;
  font-weight: bold;
  text-decoration: none;
  overflow-wrap: break-word;
  display: inline;
  word-break: break-all;
  font-size: 12px;
}



.protocolMappingStyle {
  color: #176ab3;
  font-weight: bold;
  text-decoration: none;
  overflow-wrap: break-word;
  display: inline;
  word-break: break-all;
  font-size: 12px;
}