.token-details-container {
    padding-top: 6%;
    padding-left: 10%;
    
    
}

.token-logo-container {
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    margin-top: 30px;
    min-width: 80px;
    width: auto;
  
    
}

.display-token-logo {
    width: 45px;
    height: auto;
    margin-right: 15px;
    margin-top: -10px;
    border-radius: 50%;
    
}

.token-info {
    display: flex;
    flex-direction: column;
    
    
}

.token-name {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
    
    
    
}

.token-symbol {
    font-size: 0.9rem;
    color: #555;
    margin: 0;
    font-weight: 100;
}



.token-name-and-tag {
    display: flex;
    align-items: center;
    
}


/* TokenNameAndLogoDisplay.css */
.token-chart-icon {
    width: 22px;
    /* Adjust as needed */
    height: 22px;
    /* Adjust as needed */
    margin-left: 4px;
    /* Space from the token tag */
    cursor: pointer;
    /* Indicates it's clickable */
}




/* Media query for mobile devices */
@media (max-width: 768px) {
    .token-details-container {
        padding-top: 3%; /* Reduce the top padding for tighter layout */
        padding-left: 5%; /* Reduce the left padding to align content closer to the edge */
        width: 90%; /* Full width to utilize screen space */
        /* Adjust other padding/margins as needed */
        margin-top: 30px;
    }




    .token-tag-display {
        display: flex;
        margin-left: -15%;
    }




}
