/* Adapted and renamed styles for Sierra */
.sierra-search-result-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    width: 100%;
    /* Assuming full width of the dropdown */
    box-sizing: border-box;
    /* Ensures padding doesn't add to the width */
  
}

.sierra-search-result-item:hover {
    background-color: var(--component-background-color);
    /* Lighter background on hover */
    border-radius: 6px;
    /* Rounded corners for a nicer hover effect */
}

.sierra-token-logo {
    width: 35px;
    /* Adjust size as needed */
    height: 35px;
    /* Keep it square */
    margin-right: 10px;
    border-radius: 50%;
    /* Circular logo */
}

.sierra-token-info {
    display: flex;
    flex-direction: column;
}

.sierra-token-name {
    font-weight: bold;
    font-size: 1rem;
    /* Adjust based on preference */
}

.sierra-token-address {
    color: var(--text-color);
    /* Ensure this variable is defined */
    font-size: 0.8rem;
    /* Smaller font for the address */
}



