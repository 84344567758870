/* MobileWarningModal.css */
.mobile-warning-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it's very high to sit above other content */
    font-family: 'Inter', sans-serif;
}

.mobile-warning-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #000000;
    color: #ffffff;
    font-size: 15px;
    max-width: 300px;
    /* Adjusted for better readability */
    text-align: center;
    font-family: 'Inter', sans-serif;
}

.mobile-warning-content button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #ffffff;
    /* Example button color, adjust as needed */
    color: #000;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
}


.mobile-warning-content .mobile-warning-logo {
    max-width: 100px;
    /* Or whatever size you prefer */
    margin-bottom: 20px;
    /* Space between logo and text */
}