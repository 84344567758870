/* Full page overlay that acts as the modal backdrop */
.explorer-full-page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.671);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 2rem;
    z-index: 1000;
    flex-direction: column;
}

/* The modal box itself */
.explorer-coming-soon-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #000000;
    font-size: 15px;
    color: #ffffff;
    width: 45%;
    text-align: center;
    border: 1px solid #212020;
    /* Light grey border */
}

/* Logo inside the modal */
.explorer-modal-logo {
    max-width: 100px;
    margin-bottom: 20px;
}

/* Button inside the modal */
.explorer-modal-content-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    margin-bottom: 35px;
}

/* Optional: Animation to fade in the modal on load */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.explorer-full-page-overlay {
    animation: fadeIn 0.5s ease-in-out;
}



/* Add styles for close button */
.close-modal-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: rgb(109, 106, 106);
    font-size: 0.9rem;
    cursor: pointer;
}

/* Navigation dots container */
.navigation-dots {
    display: flex;
    justify-content: center;
    padding: 10px;
}

/* Individual dots */
.dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #706e6e;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    cursor: pointer;
}

/* Active dot */
.dot.active {
    background-color: #ffffff;
}

/* Title style */
h2 {
    color: white;
    /* Add more styles as needed */
}

/* Content style */
p {
    color: white;
  
}
.modal-content-sentence {
    margin-bottom: 1rem;
    /* Adjust the bottom margin to control spacing between sentences */
    text-align: center;
    width: 80%;
}


.social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    /* Adjust the space between icons as needed */
    margin-bottom: 20px;
    /* Space above the 'Got it!' button */
}


.social-icon {
    width: 20px; /* Consistent size on all devices */
    height: 20px; /* Ensure it's always square */
    cursor: pointer;
    border-radius: 50%;
    padding: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    fill: #ffffff; /* Explicit white color, adjust as needed */
}

.social-icon:hover,
.social-icon:focus {
    background-color: rgba(255, 255, 255, 0.1);
    /* Slight white tint on hover */
    transform: scale(1.1);
    /* Slightly enlarges the icon on hover */
}



/* Responsive styles for small screens and mobile devices */
@media (max-width: 768px) {

    /* The modal box itself */
    .explorer-coming-soon-modal {
        width: 90%;
        /* Full width on smaller screens */
        font-size: 14px;
        /* Smaller font size for content */
    }

    /* Logo inside the modal */
    .explorer-modal-logo {
        max-width: 80px;
        /* Smaller logo on mobile */
        margin-bottom: 15px;
    }

    /* Button inside the modal */
    .explorer-modal-content-button {
        padding: 8px 16px;
        font-size: 14px;
    }

    /* Close button */
    .close-modal-button {
        font-size: 1.2rem;
        /* Larger close button for touch screens */
        top: 15px;
        /* Give some more space */
        right: 15px;
    }

    /* Content style */
    p,
    .modal-content-sentence {
        font-size: 14px;
        /* Smaller text in paragraphs */
    }

    /* Social icons */
    .social-links {
        gap: 15px;
        /* Less gap between social icons */
    }

    .social-icon {
        width: 20px;
        /* Smaller icons */
        padding: 6px;
        /* Adjust padding accordingly */
    }
}

/* Optionally, for very small screens such as older smartphones */
@media (max-width: 480px) {
    .explorer-coming-soon-modal {
        width: 95%;
        /* Use even more width on very small screens */
    }

        }