.wallet-connect-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    /* No background color for a clean look */
    color: white;
    border: 1px solid white;
    /* Optional: adds a white border */

    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
        width: 24px;
        height: 24px;
    }

    &:hover,
    &:focus {
        background: #0077ff;
        /* Blue background on hover/focus */
        outline: none;
        /* Removes the outline */
    }

    &:disabled {
        background: #ccc;
        cursor: not-allowed;
    }
}

/* Override styles for the WalletIcon SVG */
.wallet-connect-button svg {
    fill: white;
    /* Set SVG icon color to white */
}

/* Remove any additional overlay or background color when the button is active or focused */
.wallet-connect-button:active,
.wallet-connect-button:focus {
    background: #0056b3;
    /* Darker blue for active state */
    box-shadow: none;
    /* Removes any box shadow if present */
}

/* Specific styles to remove the overlay */
.wallet-connect-button::after {
    content: none;
    /* Removes pseudo-elements that may cause overlay */
}

.wallet-connect-button.connected {
    background: #0c5a2c;
    /* Dark green background for connected state */
}

.wallet-connect-wrapper {
    position: relative;
    /* Establishes a positioning context for dropdown */
}

.wallet-dropdown {
    position: absolute;
    right: 0;
    /* Align dropdown to the right edge of wallet button */
    top: 100%;
    /* Position it just below the wallet button */
background: #000;
    /* Dark background */
    border-radius: 10px;
    padding: 4px 12px;
    font-size: 14px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-left: 8.5%;
    margin-top: 0px;
    margin-bottom: 2px;
    
}


.wallet-dropdown button {
    background: none;
    color: white;
    padding: 8px 12px;
    border: 1px solid white;
    /* Add a white border */
    cursor: pointer;
    text-align: left;
    width: 100%;
    transition: background-color 0.2s;
    /* Optional: for a smooth background color transition */
    border-radius: 5px;
}

.wallet-dropdown button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Subtle hover effect */
}