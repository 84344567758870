/* ReferUserPage.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

.referral-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* Full viewport height */
    background: var(--general-background-color);
    /* Light background, you can adjust this */
    color: var(--text-color);
    /* Dark text color for contrast */
    font-family: 'Inter', sans-serif;
   

    
    
}





.referral-overview-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    /* Align items to the start */
    margin-top: 1vh;
    /* Adjust the top margin to bring it down */
    min-height: 20vh;

    width: 100%;
   

}

.refer-user-main-content{
   margin-top: 7%;


}





/* Media query for tablets and smaller screens */
@media (max-width: 768px) {
    .referral-container {
        flex-direction: column; /* Stack the children vertically */
    }

    .referral-overview-container {
        flex-direction: column;
        align-items: center; /* Center items when stacked */
        margin-top: 4vh; /* Increase top margin on smaller screens */
    }

    .refer-user-main-content {
        margin-top: 4%; /* Adjust top margin for smaller screens */
    }
}
