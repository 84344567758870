.sierra-trade-info-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 2rem;
    width: 55.5%;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
   
    height: 32px;
    left: 3.5%;
    top: 12.1%;
    color: var(--text-color);
}


@media (max-width: 576px) {
/* Ensure the swiper container fills its parent and works correctly on mobile */
.sierra-trade-info-panel {
        width: 90vw;
        /* Make the container full width of the viewport */
        position: relative;
        /* Use relative positioning for natural document flow */
        left: 47.2%;
        /* Center horizontally */
        transform: translateX(-50%);
        /* Offset the left positioning for true centering */
        top: 60px;
        /* Align to the top of its parent, adjust if there's some other header element */
        margin-bottom: 10px;
        /* This creates a gap between the panel and the chart */
        padding: 0.5rem;
        /* Adjust padding as necessary */
        z-index: 2;
        /* Ensure it's above the chart */
    }

    /* Swiper container should fill its parent without extra padding or margins */
    .sierra-trade-info-panel .swiper {
        width: calc(100% - 1rem);
        /* Subtract any horizontal padding */
        margin: 0 auto;
        /* Center the swiper in the container if there's extra space */
    }

    /* Address visibility of token info component */
    .sierra-trade-info-panel .token-info {
        order: 1;
        /* Ensure correct stacking order */
        display: block;
        /* Ensure it's not hidden by display: none */
        visibility: visible;
        /* Override any hidden visibility */
        /* Add any additional styles to ensure visibility */
    }

    /* Swiper slide full width within swiper container */
    .sierra-trade-info-panel .swiper-slide {
        width: 100%;
        /* Ensure slides are full width of swiper container */
        /* Add additional styles for layout within the slide */
    }



}