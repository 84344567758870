.onboarding-form {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 530px;
    padding: 20px;
    color: var(--text-color);
}

.input-label {
    color: var(--text-color);
    font-size: 12px;
    line-height: 150%;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-align: left;
}

.input-field {
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
    box-sizing: border-box;
    background: var(--component-background-color);
    font-family: Inter, sans-serif;
    color: var(--text-color);
}

.error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.onboarding-logo {
    /* Adjust the size as needed */
    width: 30%; /* Example size */
    margin-top: 50px;
    align-items: center;
    margin-bottom: -40px;
    margin-left: 30%
    
}

.intro-text p {
    text-align: center; /* Centers the text */
    margin: 20px 0; /* Adds space above and below the text */
    font-family: 'Inter', sans-serif; 
    color: var(--text-color);
    
    
}

/* IntroTextContainer.css */

.intro-text-container {
    box-sizing: border-box;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 70vh;
    max-width: 100rem;
    margin-top: 0%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0%;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    overflow: visible;
    height: auto;
    top: 3vh;
    font-size: 12px;
  
   

    
   
}


.onboarding-submit-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: -8%;
}



.recaptcha-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}




/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .onboarding-form {
        width: 90%;
        /* Use a percentage width for better responsiveness */
        max-width: none;
        /* Remove the max-width restriction */
        top: 50%;
        /* Adjust the top value to center the form vertically */
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 15px;
        /* Adjust padding */
    }

    .input-label {
        font-size: 14px;
        /* Increase font size for better readability */
    }

    .input-field {
        padding: 10px;
        width: 100%;
    }

    .onboarding-logo {
        width: 40%;
        /* Increase width for better visibility */
       
        margin-bottom: -70px;
        padding-top: 40px;
      
    }

    .intro-text-container,
    .onboarding-submit-button-container,
    .recaptcha-container {
        width: 98%;
        /* Match width with the form */
        margin-top: 20px;
        /* Provide adequate spacing */
    }

    .intro-text p {
        margin: 10px 0;
        /* Reduce space above and below the text */
    }

    .recaptcha-container {
        margin-top: 20px;
        /* Provide spacing above the reCAPTCHA */
    }
}

