@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.txDateAndTimeContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 30px;
    margin-left: 45px
}

.headerText {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
   
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-color);
}

.txDateAndTime {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
}

.dateText {
    font-size: 12px;
   
}

.txclockIcon {
    width: 1em;
    height: 1em;
    margin-right: -3px;
    
}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .txDateAndTimeContainer {
        margin-top: 20px; /* Slightly reduce the top margin */
        margin-left: 5%; /* Reduce left margin to increase readability on smaller screens */
    }

    .headerText {
        font-size: 11px; /* Slightly reduce the font size to fit better on smaller screens */
    }

    .txDateAndTime {
        /* Adjustments if needed, such as reducing gap or aligning differently */
        gap: 0.3rem; /* Reduce gap between elements */
    }

    .dateText {
        font-size: 11px; /* Slightly reduce the font size for date and time information */
    }

    .txclockIcon {
        /* Adjust icon size if necessary, but it might already be suitably sized */
        width: 0.9em; /* Slightly reduce icon size */
        height: 0.9em; /* Slightly reduce icon size */
        margin-right: -2px; /* Adjust right margin if necessary */
    }
}