.landing-page {
    text-align: center;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

.main-content {
    padding: 20px;
}

.wallet-sign-in {
    margin-top: 13%;
    display: flex;
    flex-direction: column; /* Align items vertically */
    align-items: center; /* Center items horizontally */
}

.button {
    margin: 0 auto;
    margin-top: 10px; /* Adjust as needed */
    cursor: pointer;
}


.wallet-sign-in .logo {
    width: 12%; /* Adjust the size as needed */
    margin-bottom: 20px; /* Space between the logo and the text */
    margin-right:2%
}

