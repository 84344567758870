/* TokenHoldersList.css */
.token-holders-details {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 89.1%;
    max-width: 100rem;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2%;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    overflow: auto;
    min-height: 20vh;
    top: 2vh;
    right: 7.5vh;
}

.token-holders-header-row,
.token-holder-row {
    display: grid;
    grid-template-columns:0.5fr 2fr 2fr 1fr 1fr;
    /* Adjusted to fit OWNER and AMOUNT */
    gap: 10px;
    width: 100%;
}

.token-holders-header-row {
    font-weight: bold;
    font-size: 10px;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 0.1rem;
}

.token-holder-row {
    font-size: 12px;
    border-bottom: 1px solid #c8c3c351;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 12px;
    align-items: center;
}

/* Adjust or add any additional styles for the holders list as necessary */
.token-holder-no-data {
    grid-column: 1 / -1;
    text-align: center;
    font-size: 10px;
}

.token-holder-amount-column {
    display: flex;
    align-items: center;
}

/* Reuse loading container style as it's generic enough */


.owner-with-domain {
    display: inline-flex;
   
    align-items: center;
    white-space: nowrap;
   
}

.owner-with-domain span {
    margin-left: 5px;
    /* Adjust the space as needed */
}



.pagination-controls-holders {
    border: 1px solid rgba(0, 0, 0, 0.471);
    /* Light black border */
    padding: 5px;
    /* Adjust the padding as necessary */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
   
}



.pagination-button {
    background-color: black;
     color: var(--text-color);
    border: none;
    padding: 5px 10px;
    /* Adjust as needed */
    margin: 0 5px;
    /* Adjust as needed */
    font-size: 10px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    /* Ensure Inter font is loaded */
    border-radius: 5px;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
    background-color: #333;
    /* Slightly lighter black on hover */
}

.pagination-range {
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    /* Ensure Inter font is loaded */
}


.pagination-info {
    font-size: 10px;
    
}



/* Media query for mobile devices */
@media (max-width: 768px) {
    .token-holders-details {
        /* Full width for mobile view */
        width: 89%;
        /* Adjust padding for smaller screens */
        padding: 0.5rem;
        /* Center the container */
        margin: 10px auto;
        /* Adjust the margin to fit the screen better */
        margin-left: 50px;
    }

    /* Adjusting grid layout for smaller screens */
    .token-holders-header-row,
    .token-holder-row {
        /* Adapt the grid to stack items vertically or show fewer columns */
        grid-template-columns: 3fr 3fr; /* Example for simplifying to two columns */
    }

    .token-holder-row {
        /* Adjust font size for readability on small screens */
        font-size: 12px;
    }

    .token-holder-no-data {
        /* Ensure the no-data message spans across both columns */
        grid-column: 1 / -1;
    }

    .owner-with-domain,
    .pagination-controls-holders,
    .pagination-button,
    .pagination-range,
    .pagination-info {
        /* Adjustments for smaller screen sizes */
        font-size: 12px; /* Adjust font size for better readability */
        white-space: normal; /* Allow text to wrap */
        padding: 5px; /* Adjust padding for a compact layout */
        margin: 2px 0; /* Reduce vertical margins for compactness */
    }

    .pagination-button {
        padding: 3px 5px; /* Adjust padding for a smaller button */
        font-size: 10px; /* Adjust font size for the button text */
    }

    .pagination-controls-holders {
        /* Adjust the layout for pagination controls to fit small screens */
        flex-wrap: wrap; /* Allow controls to wrap to next line if space is limited */
    }

    /* Additional adjustments may be needed based on your specific content and layout */
}
