.create-token-form {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 530px;
    padding: 20px;
    color: var(--text-color);
    /* Replace or define this variable */
}

.create-token-input-label {
    color: var(--text-color);
    /* Replace or define this variable */
    font-size: 12px;
    line-height: 150%;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-align: left;
}

.create-token-input-field {
    width: 140%;
    padding: 15px;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 8px;
    box-sizing: border-box;
    background: var(--component-background-color);
    /* Replace or define this variable */
    font-family: Inter, sans-serif;
    color: var(--text-color);
    /* Replace or define this variable */
}


.form-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust as needed */
    /* Add other styles such as padding, max-width, etc., as needed */
    margin-top: 100px;
}




.create-token-submit-button {
  width: 140%;
}