.additionalTxInfoContainer {
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: var(--general-background-color);
    margin-left: 38px;
    width: 45%;
    margin-bottom: 15px;
}

.additionalTxInfoHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
}

.additionalTxInfoTitle {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}

.additionalTxInfoArrowIcon {
    transition: transform 0.3s ease;
    font-size: 24px;
 
}

.additionalTxInfoArrowIcon.expanded {
    transform: rotate(180deg);
    /* Arrow points up when expanded */
}

.additionalTxInfoArrowIcon.collapsed {
    transform: rotate(0deg);
    /* Arrow points down when collapsed */
}

.additionalTxInfoContent {
    padding: 1rem;
    border-top: 1px solid #a1a0a087;
}

.additionalTxInfoItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 12px;
}

.additionalTxInfoLabel {
    font-weight: 500;
    color: var(--text-color) ;
    font-size: 11px;
}

.additionalTxInfoValue {
    color: var(--text-color) ;
}





/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .additionalTxInfoContainer {
        width: 95%; /* Full width for smaller screens with some padding */
        margin-left: auto; /* Center the container */
        margin-right: auto; /* Center the container */
        margin-top: 10px; /* Adjust top margin */
        margin-bottom: 10px; /* Adjust bottom margin */
        padding: 0.5rem; /* Adjust padding for content */
    }

    .additionalTxInfoHeader {
        padding: 0.5rem; /* Adjust header padding */
    }

    .additionalTxInfoTitle {
        font-size: 11px; /* Adjust title font size for smaller screens */
    }

    .additionalTxInfoArrowIcon {
        font-size: 20px; /* Adjust icon size */
    }

    .additionalTxInfoContent {
        padding: 0.5rem; /* Adjust content padding */
    }

    .additionalTxInfoItem {
        font-size: 11px; /* Adjust item font size for readability */
    }

    .additionalTxInfoLabel,
    .additionalTxInfoValue {
        font-size: 10px; /* Further adjust label and value font sizes */
    }
}