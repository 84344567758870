.sierra-token-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Add this if you want to center the children */
    background: #000;
    border-radius: 10px;
    padding: 4px 12px;
    font-size: 12px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Remove margin-left and use justify-content for centering */
    /* margin-left: 8.5%; */
    margin-top: 0px;
    margin-bottom: 2px;
    position: absolute;
    left: 10px;
    /* Adjust or remove if necessary */
    margin-right: 50px;
    /* Adjust or remove if necessary */
    /* Add a min-height or height if necessary */
    height: 25px;
    /* Example height, adjust as necessary */
    z-index: 9;
}

.sierra-panel-token-logo {
    width: 25px;
    /* Adjust size as needed */
    height: auto;
    /* Adjust size as needed */
    border-radius: 50%;
    /* Circular shape */
    margin-right: 10px;
    /* Space between logo and text */
}


.sierra-external-link-icon {
    width: 15px;
    height: auto;
    margin-left: 4px;
    cursor: pointer;
    
}



@media (max-width: 576px) {
    .sierra-token-info-container {

        background: none;
        font-size: 8px;
        
      


    }

          .token-name-symbol {
    
            
              font-size: 11px;
              min-width: fit-content;
    
    
    
          }



}