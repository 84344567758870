.sierra-token-info-chat-response-container {
    display: flex;
    flex-direction: column;
    background: #000;
    color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 10px 25px;
    width: 80%;
}

.sierra-chat-token-info-header {
    display: flex;
    align-items: flex-start;
    /* Adjust alignment */
    margin-bottom: 12px;
}

.header-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.sierra-chat-token-symbol {
    font-size: 16px;
    /* Increased for visibility */
    font-weight: bold;
}

.sierra-chat-price-changes {
    font-size: 11px;
    /* Adjusted for consistency */
    margin-top: 4px;
    /* Space between name and price details */
}

.sierra-chat-market-cap {
    font-size: 12px;
    /* Consistent font size with price change */
    margin-left: 10px;
    /* Space between percentage and market cap */
}

.sierra-chat-token-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
    margin-top: 5px;
}

.sierra-chat-token-details div {
    display: flex;
    align-items: center;
}

.sierra-chat-tick-icon,
.sierra-chat-cross-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.sierra-token-logo-info {
    width: 40px;
    height: auto;
    border-radius: 50%;
}

.sierra-chat-price-change-positive {
    color: green;
}

.sierra-chat-price-change-negative {
    color: red;
}

.sierra-chat-price-change-neutral {
    color: white;
}


