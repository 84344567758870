.wallet-display-address-container {
    padding-top: 6%;
    padding-left: 18vh;
    width: 95vh;
    margin-bottom: 12px;
    

}

.wallet-address-display {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    margin-top: 20px;
    background: var(--general-background-color);
    padding: 10px;
    height: 5vh;
    
}

.wallet-address-display .wallet-label {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color);
    padding-bottom: 5px;
    
    margin-bottom: 5px;
    
    
}

.wallet-address-display .wallet-address-info {
    display: flex;
    align-items: center;
}

.wallet-address-display .wallet-address {
    font-size: 1rem;
    color: var(--text-color);
    padding-left: 5px;
    margin-top: 1px;
   
   
   
    /* Add padding for better visibility */
}


.address-tag-icon {
    margin-left: 10px;
    height: auto;
    width: 100px;
    margin-bottom: -4px;
    
}




.wallet-address-info .icon-generator {
    border-radius: 50%;
    
    overflow: hidden;
    margin-right: 10px;
    /* Space between icon and address */
    width: 55px;
    /* Adjust size as needed */
    height: auto;
    /* Adjust size as needed */
}


.wallet-address-info {
    display: flex;
    align-items: center;
    position: relative;
    /* Add this line */
}


.wallet-address-info .notification-container {
    position: relative;
    /* Ensure this is relative for the indicator to position correctly */
    display: flex;
    align-items: center;
    display: none;
}

.active-notification-indicator {
    width: 5px;
    height: 5px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    /* Adjust this value as needed to align correctly */
    right: 4px;
    /* Adjust based on the size of the bell icon */
}


.wallet-address-copy-icon-dark {
    cursor: pointer;
    margin-left: 6px;
    width: 18px;
}

.wallet-address-copy-icon {
    cursor: pointer;
}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .wallet-display-address-container {
        padding-top: 3%;
        padding-left: 5%; /* Reduce left padding */
        width: 100%; /* Full width for smaller screens */
        margin-bottom: 0px; /* Adjust bottom margin */
        margin-top: 35px;
    }

    .wallet-address-display {
        padding: 10px;
        height: auto; /* Adjust height to content */
    }

        .wallet-address-display .wallet-label {
            font-size: 1rem; /* Smaller font size for smaller screens */
        }

   

        .wallet-address-display .wallet-address {
            font-size: 12px; /* Smaller font size for address */
            margin-left: 5px; /* Remove left padding */
            word-break: break-all; /* Break the address to prevent overflow */
        }

    .address-tag-icon,
    .wallet-address-info .icon-generator {
        width: 40px; /* Smaller icon size */
        height: auto; /* Adjust height automatically */
    }


   

    .wallet-address-info .notification-container {
        justify-content: flex-start; /* Align notification to the start */
        display: none;
    }

   


}