.txSummaryAssetDisplay {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;

}

.assetDisplayContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border-radius: 8px;
    margin-left: 45px;
    background-color: aliceblue;
    
   
}



.asset {
    display: flex;
    align-items: center;
    gap: 5px;
    /* Space between logo, amount, and symbol */
}

.SOLassetLogo {
    width: 18px;
    height: auto;
    margin-left: 2px;
   
}


.AssetSummaryTokenLogo {
    width: 20px;
    height: auto;
    margin-right: 5px;
    border-radius: 50%;
}



.assetAmount {
    font-size: 12px;
    color: var(--text-color);
    /* This uses the color set by the theme */
}


.assetSymbol {
    font-size: 12px;
    /* Adjust as needed */
}




.transactionArrow {
    font-size: 16px;
    /* Size for visual emphasis */
    color: #000000;
    /* Adjust color as needed */
    margin: 0 1rem;
}




.txSummaryAssetDisplay .assetDisplayContainer .asset .senderAddressLink {
    font-size: 12px !important;
    /* Override styles with !important */
}

.txSummaryAssetDisplay .assetDisplayContainer .asset .receiverAddressLink {
    font-size: 12px !important;
    /* Override styles with !important */
}





/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .txSummaryAssetDisplay {
        margin-top: 1rem; /* Reduce top margin */
    }

    .assetDisplayContainer {
        padding: 2px; /* Reduce padding */
        margin-left: 5%; /* Reduce left margin to increase usable space */
        width: 100%; /* Increase width for better visibility of content */
        background-color: aliceblue; /* No change needed unless design dictates */
    }

    .asset {
        gap: 3px; /* Reduce gap for compact display */
    }

    .SOLassetLogo,
    .AssetSummaryTokenLogo {
        width: 15px; /* Slightly reduce icon size */
        /* Adjust height accordingly if needed */
    }

    .assetAmount,
    .assetSymbol {
        font-size: 11px; /* Slightly reduce font size for consistency */
    }

    .transactionArrow {
        font-size: 14px; /* Slightly reduce size for space efficiency */
        margin: 0 0.5rem; /* Reduce margin for compact display */
    }

    .txSummaryAssetDisplay .assetDisplayContainer .asset .senderAddressLink,
    .txSummaryAssetDisplay .assetDisplayContainer .asset .receiverAddressLink {
        font-size: 11px !important; /* Reduce font size and ensure override */
    }
}