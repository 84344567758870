.txStatusTypeContainer {
    display: flex;
    align-items: center;
    gap: 0px;
    margin-top: 10px;
    margin-left: 30px;
    flex-direction: column;
        align-items: flex-start;

}

.TxstatusTag,
.TxtypeTag {
    display: flex;
    align-items: center;
    gap: 0px;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    margin-right: -25px;
}



.txstatusTag svg,
.txtypeTag svg {
    width: 20px;
    height: 20px;
}


.txStatusTypeTitle {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-color);
    margin-bottom: 2px;
    margin-left: 16px;
    
}

.tagGroup {
    display: flex;
    gap: 2px;
    
}




@media (max-width: 768px) {
    .txStatusTypeContainer {
        margin-top: 8px; /* Adjust top margin for tighter layout */
        margin-left: 5%; /* Align with the rest of the content on small screens */
        flex-direction: column; /* Maintain column layout */
        align-items: center; /* Center align the tags for better readability */
    }

    .TxstatusTag, .TxtypeTag {
        padding: 0.3rem 0.8rem; /* Adjust padding for smaller tags */
        font-size: 0.8rem; /* Adjust font size for legibility */
        margin-right: 0; /* Reset right margin */
    }

    .txStatusTypeTitle {
        font-size: 11px; /* Adjust title font size */
        margin-left: 0; /* Align title with tags */
    }

    .tagGroup {
        gap: 1px; /* Reduce gap for compact layout */
    }
}
