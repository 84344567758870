/* TokenTagComponent.css */
.transaction-tag-display {
    background-color: #000;
        color: #fff;
        padding: 4px 10px;
        border-radius: 6px;
        font-size: 9px;
        font-weight: normal;
        display: inline-block;
        /* or 'block' if you want it to take up its own line */
        margin-right: 10px;
        margin-bottom: 3px;
}