/* WalletDataButtonNavigation.css */
.wallet-data-navigation {
    /* Unique class name */
    text-align: left;
    margin-top: 20px;
    margin-left: 9.3vw;
}

.wallet-data-navigation button {
    /* Unique class name */
    display: inline-block;
    font-size: 1rem;
    color: var(--text-color);
    background: var(--component-background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 5px;
}

.wallet-data-navigation button:hover {
    /* Unique class name */
    background-color: #010c47;
}





/* Media query for mobile devices */
@media (max-width: 768px) {
    .wallet-data-navigation button {
       
        margin-left: -4%;
    }

}
