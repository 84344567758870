/* BlackfinChart.css */

.blackfin-chart-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    flex-grow: 1;
    /* Takes up the remaining space */
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    height: 58.5vh;
    /* Or as required */
    margin-left: 2rem;
    /* To create space between the details and chart */
    width: 42%;
    position: absolute;
    top: 0.3%;
    right: 12.8%;
}

.blackfin-chart-iframe {
    width: 100%;
    height: 100%; /* You may want to adjust this to fit the container */
    border: none; /* Remove the frame border */
}


@media (max-width: 768px) {
    .blackfin-chart-container {
        position: static;
        /* Remove absolute positioning */
        width: 82%;
        /* Full width of the screen */
        margin-left: 19px;
        /* Remove the left margin */
        height: auto;
        /* Adjust height to content */
        top: auto;
        /* Reset top position */
        right: auto;
        /* Reset right position */
        flex-grow: 0;
        /* Disable flex-grow as it's full width now */
        margin-top: 1rem;
        /* Add some space above the chart */
        /* Adjust padding, margin, and other properties as needed */
    }
}