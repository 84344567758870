/* Original styling for larger screens */
.wallet-info-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;

   

    width: 40%; /* Use a specific width for larger screens */

    max-width: 100rem;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    overflow: hidden;

    max-height: 29.1vh;
    top: 3vh;
    right: 2.6vw;
}








.wallet-info-container .info-label {
    display: block;
        font-family: 'Inter', sans-serif;
        font-size: 10px;
        color: var(--text-color);
        text-transform: uppercase;
        font-weight: bold;
}

.wallet-info-container .info-data {
    font-family: 'Inter', sans-serif;
        font-size: 12px;
        color: var(--text-color);
}

.wallet-info-container .copy-icon {
    margin-left: 10px;
        /* Space from the address text */
        cursor: pointer;
        /* Indicates an interactive element */
}

.wallet-info-container .icon {
    width: 30px;
    /* Example size, adjust as needed */
    height: auto;
    /* Example size, adjust as needed */
}






.wallet-info-container .info-title {
    font-family: 'Inter', sans-serif;
    color: var(--text-color);
    font-size: 15px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.wallet-info-container .info-item {
    margin-bottom: 12px;
}


.wallet-info-domain-name {
    display: inline-block;
    font-size: 12px;
    margin-top: 5px;
    padding: 4px 8px;
background: var(--component-background-color);
    color: var(--text-color);
    border: 1px solid #0000FF;
    border-radius: 10px;
    margin-right: 5px;
    cursor: pointer;
}

.domain-names-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    margin-bottom: -8px;
    align-items: center;


}


.more-domains-indicator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 20px;
background: var(--component-background-color);
    border: 1px solid #d0d0d0;
    padding: 2px 4px;
    border-radius: 10px;
    font-size: 14px;
    margin-left: 8px;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    font-weight: 600;
    

    
}

.wallet-info-domain-name:hover {
background: var(--component-background-color);
    /* Light grey background on hover */
}


.no-domains-info {
 margin-top: 6px;
 font-size: 12px;
 margin-bottom: 10px;
   
}



/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .wallet-info-container {
        width: 100%; /* Full width for smaller screens */
        max-width: 95%; /* Slightly less than full width for some padding */
        padding: 10px; /* Adjust padding for smaller screens */
        margin: 20px 5px; /* Adjusted margin to center the container */
        top: 0;

      
        right: 0;
    }

        .wallet-info-container .info-label,
        .wallet-info-container .info-data,
        .wallet-info-container .info-title,
        .wallet-info-domain-name,
        .domain-names-container,
        .more-domains-indicator {
            font-size: 10.8px; /* Adjust font size for smaller screens */
        }

        .wallet-info-container .copy-icon,
        .wallet-info-container .icon {
            width: 20px; /* Adjust icon sizes */
            height: auto;
        }
}


