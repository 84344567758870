.explorer-container {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Full viewport height */
    background-image: url('../../Media/Icons/MainBg.webp');
    /* Adjust the path as needed */
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
}





.explore-page-black-logo {
    position: absolute;
    margin-top: -10px;
    margin-bottom: 2px;
    width: 150px;
    height: auto;
    left: 50%;
    /* Center horizontally */
    top: 30vh;
    transform: translateX(-50%);
    /* Adjust for center alignment */
}



.explorer-content {
    margin-top: -50px;
  
}



/* Mobile optimized styles */
@media (max-width: 768px) {
    .explore-page-black-logo {
        width: 120px; /* Slightly smaller logo for mobile */
        top: 20vh; /* Position it higher on the viewport */
        margin-top: 0; /* Adjust top margin as needed */
    }

    .explorer-content {
        margin-top: 10vh; /* Provide some top margin to give space below the logo */
        padding: 0 20px; /* Add padding on the sides for some breathing room */
    }

    /* If you have a search bar or other content inside .explorer-content, you may need additional styling */
    .search-bar {
        width: 100%; /* Full width search bar on mobile */
        margin-bottom: 20px; /* Space below the search bar */
    }

    /* Add other responsive adjustments as needed */
}