.overview-and-info-container {
    display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
        margin-top: -1%;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        max-width: 100%;
        flex-wrap: wrap;
        padding: 0 1rem;
        /* Add some padding on both sides */
    
    
}






/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .overview-and-info-container {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        gap: 0.5rem; /* Adjust gap to reduce spacing */
        padding: 1rem 0; /* Increase padding on the top and bottom */
    }
}