.sierra-chart-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Adjusted to center for visual consistency */
    padding: 10px;
    width: 90%;
    /* Use a specific width for larger screens */
    max-width: 100rem;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    /* Ensure you have this variable defined in your CSS or replace with a specific color */
    overflow: auto;
    height: 75.5vh;
    top: 20vh;
    right: -1.5%;
}



.token-chart-iframe {
    width: 100%;
    /* Adjusted to fill the container */
    height: 100%;
    /* Adjusted to fill the container */
    border: none;
 
}


/* Responsive adjustments for the sierra-chart-container on mobile */
@media (max-width: 576px) {
.sierra-chart-container {
        padding: 10px;
        width: 100%;
        /* Full width for mobile */
        max-width: 100%;
        /* Prevent exceeding screen width */
        margin-top: 10px;
        /* This creates a space between the top of the chart and the bottom of the info panel */
        height: 70%;
        /* Adjust height to give space for the info panel above */
        position: relative;
        /* Relative to allow natural document flow */
        z-index: 1;
        /* Below the trade info panel */
        left: 20px;
        overflow: hidden;
        bottom: 20px;
    }



    .token-chart-iframe {
        width: 100%;
        /* Ensure the iframe fills its container */
        height: 100%;
        /* Ensure the iframe fills its container */
        border: none;
    }
}