.header-solana-price-container {
    display: flex;
    align-items: center;
    background: #000;
    /* Dark background */
    border-radius: 10px;
    padding: 4px 12px;
    font-size: 14px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-left: 8.5%;
    margin-top: 0px;
    margin-bottom: 2px;
    /* Optional: adds a slight shadow for depth */
    
}

.solana-logo {
    width: 24px;
    /* Adjust size as needed */
    height: auto;
    margin-right: 8px;
    /* Space between logo and price */
}

.solana-price {
    margin-right: 8px;
    /* Space between price and price change */
    font-weight: normal;
}

.solana-price-change {
    color: #f44336;
    /* Red color to indicate a negative change */
    font-weight: bold;
}




/* Overriding styles for smaller screens */
@media (max-width: 768px) {
    .header-solana-price-container {
        padding: 4px 8px;
        /* Slightly reduce padding */
        font-size: 12px;
        /* Reduce font size */
        margin-left: 1rem;
        /* Adjust margin-left to a relative size */
        display: none;
    }

    .solana-logo {
        width: 20px;
        /* Slightly reduce logo size */
        margin-right: 4px;
        /* Reduce space between logo and price */
    }

    .solana-price {
        margin-right: 4px;
        /* Reduce space between price and price change */
    }

    .solana-price-change {
        /* Optional: Adjust font size if necessary */
        font-size: 12px;
    }
}

/* Additional adjustments for very small screens, like mobile phones */
@media (max-width: 480px) {
    .header-solana-price-container {
        padding: 2px 6px;
        /* Further reduce padding */
        font-size: 11px;
        /* Further reduce font size */
        margin-left: 0.5rem;
        /* Further adjust margin-left */

        display: none;
    }

    .solana-logo {
        width: 18px;
        /* Further reduce logo size for very small screens */
    }

    .solana-price,
    .solana-price-change {
        font-size: 11px;
        /* Adjust font size for consistency */
    }
}