.side-panel {
    width: 2.5%;
    height: 100vh;
    background-color: black;
    color: white;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    
    padding-top: 1rem;
    z-index: 90000;
    box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
    /* Horizontal offset, vertical offset, blur radius, color */
    
}
.side-panel-logo {
    width: 25px; /* Adjust as needed */
    margin-bottom: 45%; /* Spacing below the logo */
    margin-left: 4px;
    
}

.menu-item {
    margin: 10px 0;
    cursor: pointer;
    color: white;
}

/* Add more styling here as needed */

.green-dot-container {
    margin-top: auto;
    /* Pushes the container to the bottom */
    padding-bottom: 1rem;
    /* Spacing from the bottom of the panel */
    width: 100%;
    /* Ensure the container fills the side panel width */
    display: flex;
    justify-content: center;
    /* Center the dot horizontally */
    margin-bottom: 15px;
}

.green-dot {
    width: 7px;
    /* Size of the dot */
    height: 7px;
    /* Size of the dot */
    background-color: #0e910e;
    /* Green color */
    border-radius: 50%;
    /* Makes it circular */
    box-shadow: 0 0 8px #00FF00;
    /* Optional: Adds a glow effect */
    /* Animation */
    animation: flash 2s infinite;
    /* Flashing effect with 2 seconds cycle */
}

/* Keyframes for flashing animation */
@keyframes flash {

    0%,
    100% {
        opacity: 1;
    }

    /* Fully visible at the start and end */
    50% {
        opacity: 0.3;
    }

    /* Partially transparent in the middle */
}


.menu-item-active::before {
    content: '';
    position: absolute;
    top: 29%;
    left: 34%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(231, 3, 3);
}




.menu-item-swap-container {
    margin-top: 5px;
    /* Ensure consistency in spacing */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    width: 100%;
    padding-top: 3px;
}

.menu-item-swap-container:hover {
    background-color: #221f1f;
    /* Consistent hover effect */
}

.swap-icon {
    width: 22px;
    height: 22px;
    /* Consistent icon sizing */
}

/* SidePanel.css */
.menu-item-explore-container {
    margin-top: 5px;
    /* Adjust spacing as needed */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    width: 100%;
    padding-top: 3px;
    /* Adjust padding as needed */
}

.menu-item-explore-container:hover {
    background-color: #221f1f;
    /* Dark grey background color on hover */
}

/* Set the size for the Explore icon */
.explore-icon {
    width: 22px;
    height: 22px;
}




/* Portfolio link container */
/* Portfolio link container */
.menu-item-portfolio-container {
    margin-top: 5px;
    /* Space from the previous menu item */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    width: 100%;
    padding-top: 3px;
    display: none;
}

.menu-item-portfolio-container:hover {
    background-color: #221f1f;
    /* Dark grey background color on hover */
    display: none;
}

/* Set the size for the Portfolio icon */
.portfolio-icon {
    width: 22px;
    height: 22px;
    display: none;
}


.discord-link-container {
    position: absolute;
    bottom: 76px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    /* Add rounded corners */
    transition: background-color 0.3s ease;
    /* Smooth transition for background color */
    padding-top: 3px;
    margin-bottom: 10px;
}

.discord-link-container:hover {
    background-color: #221f1f;
    /* Dark grey background color on hover */
}



.discord-logo {
    /* Set size of the Discord logo */
    width: 22px;
    /* Adjust size as needed */
    height: 22px;
    /* Adjust size as needed */
}



.twitter-link-container {
    position: absolute;
    bottom: 110px;
    /* Adjust this value so the Twitter logo appears above the Discord logo */
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    /* Rounded corners */
    transition: background-color 0.3s ease;
    padding-top: 3px;
    margin-bottom: 10px;
}

.twitter-link-container:hover {
    background-color: #221f1f;
    /* Dark grey background color on hover */
}

/* Set the size for the Twitter logo */
.twitter-logo {
    width: 20px;
    height: 20px;
}



.refer-users-link-container {
    position: absolute;
    bottom: 153px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    /* Add rounded corners */
    transition: background-color 0.3s ease;
    /* Smooth transition for background color */
    padding-top: 3px; 
    display: none;
}


.refer-users-link-container:hover {
    background-color: #221f1f;
    /* Dark grey background color on hover */
    /* Add any other hover styles you want here */
}


.refer-users-logo {
    /* Style the refer users logo */
    width: 22px;
    /* Adjust size as needed */
    height: 22px;
    /* Adjust size as needed */
}



.docs-link-container {
    position: absolute;
    bottom: 50px;
    /* Adjust based on the actual position below the referral icon */
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    padding-top: 3px;
}

.docs-link-container:hover {
    background-color: #221f1f;
    /* Customize the color if needed */
}

.docs-logo {
    width: 24px;
    /* Adjust size as needed */
    height: 24px;
    /* Adjust size as needed */
}


.notification-bell-link-container {
    position: absolute;
    bottom: 196px;
    /* Adjust this value to position the icon correctly */
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    padding-top: 3px;
    display: none;
}

.notification-bell-link-container:hover {
    background-color: #221f1f;
    /* Dark grey background color on hover */
    display: none;
}

.notification-bell-logo {
    width: 22px;
    /* Adjust size as needed */
    height: 22px;
    /* Adjust size as needed */
    display: none;
}









/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .side-panel {
            display: none;
        }

    .spacer {
        flex-grow: 1;
        /* This will push all elements below it to the bottom */
    }
    .menu-item {
        margin: 5px 0;
        /* Adjust spacing of menu items */
    }

    
 .menu-item,
 .discord-link-container,
 .twitter-link-container,
 .green-dot-container {
     /* Reset any top or bottom positioning */
     position: static;
     margin-top: 5px;
     margin-bottom: 5px;
 }
}


/* Responsive adjustments for very small screens, like mobile */
@media (max-width: 480px) {

    /* Hide the side panel */
    .side-panel {
        display: none;
    }

   
}

   