.notification-modal-content-row {
    display: grid;
    grid-template-columns: 100px 1fr 120px auto;
    /* This should match the header row's template */
    align-items: center;
    gap: 10px;
    padding: 2px;
    border-bottom: 1px solid #eaeaea;
}

.notification-modal-icon {
    width: 20px;
    height: 20px;
}

.notification-modal-type-column {
    white-space: nowrap;
    justify-self: start;
    min-width: 80px;
}

.notification-modal-description-column {
    justify-self: start;
    padding-left: 10px;
}

.notification-modal-time-column {
    justify-self: left;
    padding-right: 10px;
}

.notification-modal-action-column {
    justify-self: center;
}

.notification-modal-view-button {
    padding: 8px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}

.notification-modal-view-button:hover {
    background-color: #0056b3;
}


