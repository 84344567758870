@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.txHashDisplayContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 20px;
    /* Adjust top margin as needed */
    margin-left: 45px;
    /* Keep it consistent with the date and time component */
}

.txHashheaderText {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-color);
}

.txHashDisplay {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.hashText {
    font-size: 12px;
    word-break: break-all;
    /* Ensure long hashes don't overflow */
}

.txHashIcon {
    width: 1em;
    height: 1em;
    margin-right: -3px;
}

.hashDivider {
    height: 1px;
    background-color: #e9e9e9;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 118vh;
}




/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .txHashDisplayContainer {
        margin-top: 15px; /* Slightly reduce the top margin for mobile screens */
        margin-left: 5%; /* Reduce the left margin for better alignment on smaller screens */
    }

    .txHashheaderText {
        font-size: 11px; /* Reduce the font size to fit more content on smaller screens */
    }

    .txHashDisplay {
        gap: 0.3rem; /* Reduce the gap between elements for a compact look */
    }

    .hashText {
        font-size: 11px; /* Adjust the font size for better readability */
        /* Additional styling to ensure text breaks properly might not be necessary if 'word-break: break-all;' is already applied */
    }

    .txHashIcon {
        width: 0.9em; /* Slightly reduce the icon size to match the adjusted text size */
        height: 0.9em; /* Adjust height accordingly */
        margin-right: -2px; /* Adjust the right margin to maintain alignment */
        display: none;
    }

    .hashDivider {
        margin-top: 8px; /* Adjust the margins to ensure visual separation without taking too much space */
        margin-bottom: 8px;
        width: 90%; /* Adjust width to match the container's width on smaller screens */
    }
}