
/* ChatInputBox.css */


.chat-input-container {
    /* Removed position: fixed and related properties */
    width: 100%;
    /* Let it take the full width of its parent container */
    
    /* Padding around the input */
    box-sizing: border-box;
    /* Include padding in the width */
    background-color: rgba(255, 255, 255, 0);
        /* Background color - semi-transparent white */
        backdrop-filter: blur(2px);
        /* Apply blur */
   
    /* Adjust border as needed */
    display: flex;
    /* Continue using flexbox */
    justify-content: center;
    /* Center children horizontally */
    align-items: center;
    height: -20px;

}





.chat-input {
    width: 80%;
    padding: 3%;
    box-sizing: border-box;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    resize: none;
    height: auto;
    min-height: 20%;
    font-family: 'Inter', sans-serif;
    overflow: hidden; /* Prevent scrollbar from appearing */
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: none; /* This will remove the permanent border */
    padding-right: 90px;
    background: var(--component-background-color);
    color: var(--text-color);
    margin-left: 12%;
  


}




.chat-send-button {
    position: relative; /* this takes the button out of the normal flow, and positions it relative to the input-container */
    right: 10%; /* adjust as needed, this positions the button from the right */
    bottom: 17%; /* adjust as needed, this positions the button from the bottom */
    background: none; /* remove button default styling */
    border: none; /* remove button default styling */
    cursor: pointer; /* change the mouse cursor when hovering over the button */
   
    
}

.chat-send-icon-dark {
   width: 20px;
   height: auto;

   
}

.chat-input:focus {
    outline: none; /* This will remove the border when the input is focused */
}






.loader-container {
    position: absolute;
    top: 30%;
    left: 55%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}



/* Media query for devices with width less than 600px */
@media (max-width: 600px) {
    .chat-input {
       font-size: 16px;
    }
}