
.dropdown-item-container {
    display: grid;
    grid-template-columns: 3fr 2fr 2fr;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #afaeae70;
    cursor: pointer;
    background: var(--component-background-color);
    transition: background-color 0.3s;
}

.dropdown-item-container:hover {
    background: var(--component-background-color);
    
}

.dropdown-item-icon-label {
    display: flex;
    align-items: center;
}

.dropdown-item-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 14px;
    object-fit: cover;
    
}


.dropdown-item-amount {
    font-size: 11px;
    margin-left: 10px;
   
}


.dropdown-item-value {
    font-size: 11px;
    margin-left: 5px;

}

.dropdown-item-token-name {
    font-size: 11px;
    margin-left: 4px;

}
