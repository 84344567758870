.trending-tokens-panel {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5rem 2rem;
    width: 178vh;
    max-width: 178vh;
    margin: 120px auto 5rem auto;
    margin-top: 4%;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: hidden; 
    left: 2%;
}


.trending-overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100px; /* Adjust the width to cover the icon and text */
    background: #ffffff;
    z-index: 1; /* Must be above the scrolling tokens but below the text */
}


.trending-header {
    display: flex;
    align-items: center;
    background: #ffffff; /* Background to cover tokens */
    
    z-index: 2; /* Above the tokens */
    padding-right: 1%;


}

.trending-tokens-scroll {
    display: flex;
    white-space: nowrap;
    margin-left: 100px; /* Adjust based on the size of the header */
    animation: ticker 15s linear infinite;
    
}


    .trending-tokens-scroll:hover {
        animation-play-state: paused; /* Pause animation on hover */
    }



.trending-token {
    display: inline-flex;
    justify-content: space-between;
    margin-right: 1rem;
    align-items: center;
    font-size: 14px;
}

@keyframes ticker {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.trending-icon {
    width: 20px;
    height: auto;
    margin-right: 0.5rem;
    z-index: 3;
    
}

.trending-text {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
   
}
