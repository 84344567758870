.token-info-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 43%;
    max-width: 100rem;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    overflow: auto;
    height: auto;
    top: 3vh;
    right: 5.8%;
}

.info-title {
    font-family: 'Inter', sans-serif;
    color: var(--text-color);
    font-size: 15px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.info-item {
    margin-bottom: 12px;
}

.info-label {
    display: block;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: bold;
}

.info-data {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: var(--text-color);
    
}





.copy-icon {
    margin-left: 10px;
    /* Space from the address text */
    cursor: pointer;
    /* Indicates an interactive element */
}

.icon {
    width: 30px;
    /* Example size, adjust as needed */
    height: auto;
    /* Example size, adjust as needed */
}


.copy-icon {
    margin-left: 10px;
    /* Space from the address text */
    cursor: pointer;
    /* Indicates an interactive element */
}


.info-social-icon {
    width: 18px;
        /* Example size, adjust as needed */
        height: auto;
        /* Example size, adjust as needed */
        margin-left: 4px;
        margin-top: 2px;
}



/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .token-info-container {
        width: 89%; /* Full width for better utilization of space */
        top: 0; /* Reset top positioning */
        right: 0; /* Reset right positioning */
        margin-top: 10px; /* Provide some top margin */
        margin-right: 50px;
    }

    .info-title,
    .info-label,
    .info-data {
        font-size: 11px; /* Adjust font size for better readability */
        word-wrap: break-word; /* Ensure long words can break and wrap to the next line */
        white-space: normal;
    }

    .copy-icon,
    .icon {
        width: 24px; /* Adjust icon size for smaller screens */
        height: auto; /* Maintain aspect ratio */
    }
}