/* SearchResultItem.css */
.search-result-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    min-width: 95vw;
}




.search-result-item:hover {
background: var(--component-background-color);
    border-radius: 6px;
}

.main-searchbar-token-logo {
    width: 35px;
    /* Adjust size as needed */
    height: auto;
    /* Adjust size as needed */
    margin-right: 10px;
    border-radius: 50%;
    margin-bottom: 30px;
}

.token-info {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    margin-bottom: 16px;
}

.main-searchbar-token-name {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    font-size: 8px;

}

.main-searchbar-token-address {
    color: var(--text-color);
    /* Dark grey for address */
    font-size: 0.8rem;
    font-family: 'Inter', sans-serif;
}



.main-searchbar-token-holders {
    color: var(--text-color);
    /* Dark grey for holders */
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin-top: 5px;
}


/* Add this to your main search bar CSS file, not the TokenTagComponent.css */
.main-searchbar-token-name-and-tag .token-tag-display {
    margin-left: 10px;
    /* Override the negative margin */
    margin-right: 2px;
    /* Add some space to the right of the tag */
    margin-bottom: 2px;
}









.main-searchbar-token-symbol {
    font-weight: bold;
}









/* styles for the wallet addresss search tiem drodpwon */
.main-searchbar-wallet-name {
        font-weight: bold;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
}

.main-searchbar-wallet-address {
        color: var(--text-color);
        font-size: 0.8rem;
        font-family: 'Inter', sans-serif;
}

.main-searchbar-wallet-balance {
    color: var(--text-color);
    /* Dark grey for holders */
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin-top: 5px;
}



.icon-generator-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 28px;
    margin-right: 10px;
   
}



.domain-name-container {
    /* Adjust the width as needed */
    width: 100%;
    text-align: left;
   
}



.search-result-domain-tag, .search-result-no-domain-tag {
    /* Shared properties */
    display: inline-flex;
    font-size: 12px;
    margin-top: 5px;
    padding: 4px 8px;
    background-color: rgb(230, 230, 230); /* Default background */
    color: #000000;
    border-radius: 10px;
    margin-right: 5px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 2px;
    border: 1px solid darkgrey; /* Default border */
}

.search-result-domain-tag {
    background-color: #E6EFFF;
    border-color: #0000FF;
    cursor: pointer;
}

.search-result-no-domain-tag {
    cursor: default;
}








.search-result-domain-names-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    margin-bottom: -8px;
    align-items: center;
}

.search-result-wallet-info {
    display: block;
    /* or 'flex' with 'flex-direction: column' */
}

.tx-info-flex-container {
    display: flex;
    align-items: center;
    /* Vertically center the items */
    gap: 10px;
    /* Spacing between items */
}

.tx-type-icon,
.tx-status-icon {
    height: 24px;
    /* Set a standard height */
    width: auto;
    /* Maintain aspect ratio */
    object-fit: contain;
    /* Ensures the image is scaled correctly */
    vertical-align: middle;
    /* Aligns the image vertically in line with the text */
}

.tx-type,
.tx-status {
    display: flex;
    align-items: center;
    /* Align text and icons in the same line */
}




 .tx-hash-display {
    color: var(--text-color);
        /* Dark grey for address */
        font-size: 12px;
        font-family: 'Inter',
            sans-serif;
        margin-top: -6px;
        margin-bottom: 3px;

    
       
        
 }



 /* Responsive adjustments for search result items */
 @media (max-width: 768px) {
     .search-result-item {
         padding: 0.5rem;
         /* Adjust padding for smaller screens */
     }

     .main-searchbar-token-logo,
     .main-searchbar-token-tag-svg,
     .tx-type-icon,
     .tx-status-icon {
         width: auto;
         /* Adjust width to maintain aspect ratio */
         height: 1.5rem;
         /* Adjust height for smaller screens */
         margin-right: 0.5rem;
         /* Adjust margin to fit smaller screens */
     }

     .token-info,
     .main-searchbar-token-name,
     .main-searchbar-token-address,
     .main-searchbar-token-holders,
     .main-searchbar-wallet-name,
     .main-searchbar-wallet-address,
     .main-searchbar-wallet-balance,
     .search-result-domain-tag,
     .search-result-no-domain-tag,
     .tx-hash-display {
         font-size: 0.8rem;
         /* Adjust font size for better readability on small screens */
     }

     /* Adjust flex containers to wrap on smaller screens */
     .icon-generator-container,
     .domain-name-container,
     .search-result-domain-names-container,
     .tx-info-flex-container {
         flex-wrap: wrap;
         /* Allow items to wrap to next line */
     }

     .search-result-domain-tag,
     .search-result-no-domain-tag {
         padding: 0.25rem 0.5rem;
         /* Adjust padding for tags */
     }


    .token-tag-display {
      display: none;
    }
 }


/* Adjustments for smaller screens */
@media (max-width: 480px) {
    .search-result-item {
        /* Keep the layout horizontal */
        align-items: center;
        /* Align items vertically in the center */
        padding: 8px;
        /* Slightly reduce padding */
        
    }

    .main-searchbar-token-logo {
        width: 28px;
        /* Slightly reduce the logo size */
        height: auto;
        /* Maintain aspect ratio */
        margin-right: 8px;
        /* Adjust spacing */
    }


    .token-tag-display {
        display: none;
    }

   

    .main-searchbar-token-name,
    .main-searchbar-token-address,
    .main-searchbar-token-holders {
        font-size: 9px;
        /* Slightly reduce font size for better fit */
    }

    .main-searchbar-token-symbol {
        font-size: 12px;
        /* Match font size with other texts */
    }

    .search-result-domain-tag,
    .search-result-no-domain-tag {
        font-size: 11px;
        /* Reduce font size for tags */
        padding: 2px 4px;
        /* Adjust padding inside the tags */
    }

    

    .main-searchbar-token-tag-svg {
        width: 36px;
        /* Adjust SVG icon size */
        height: auto;
        /* Adjust SVG icon size */
    }

   
    .search-result-wallet-info {
        /* Keep the wallet info inline if it fits */
        flex-direction: row;
    }


        .tx-hash-display {
            font-size: 11px;
            /* Optionally adjust font size for small screens */
            /* Continue with the wrapping properties */
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
}