.main-portfolio-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center items in the card */
    padding: 5px;
    /* Adjust padding to control height */
    width: 35vh;
    /* You may also want to adjust the width if necessary */
    max-width: 50rem;
    /* Adjust max-width for better responsiveness */
    margin: 2% 1%;
    /* Provide space around the card */
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: visible;
    height: 100px;
    font-size: 12px;

}


/* Specific class for the card value */
.main-portfolio-card-value {
    font-size: 14px;
    /* Increased font size for the value */
    color: #333;
    /* Adjust the color if necessary */
}