.mobile-message-container {
    display: flex;
    flex-direction: column;
    /* Stack flex items vertically */
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    height: 100vh;
    width: 100%;
    font-size: 20px;
    background-color: #000;
    color: #fff;
    border-radius: 0;
    box-sizing: border-box;
}


.mobile-logo-view {
    max-width: 150px;
    /* or any other size */
    margin-bottom: 20px;
    /* Space between logo and text */
}


.social-icon-X {

        transition: transform 0.3s ease;
        margin-left: 14px;
}