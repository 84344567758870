



.chat-message-list-container {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    padding-left: 2%;
    padding-right: 2%;
    scroll-behavior: smooth;
    padding-top: 2cm;

}


    .chat-message-list-container > :last-child {
        margin-bottom: 5%; /* Adjust so it's the same as the height of the input box */
    }


    /* This styles the scrollbar track */
    .chat-message-list-container::-webkit-scrollbar-track {
        background: var(--component-background-color);
    }

    /* This styles the scrollbar handle */
    .chat-message-list-container::-webkit-scrollbar-thumb {
        background: var(--component-background-color);

    }

    /* This styles the scrollbar itself (width, etc.) */
    .chat-message-list-container::-webkit-scrollbar {
        width: 2px; /* You can adjust the width */
    }


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.sierra-token-trending-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Changed to flex-start for better alignment of the content */
    background: #000;
    border-radius: 10px;
    padding: 4px 12px;
    font-size: 12px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 3px;
    /* adjusted margins for spacing between entries */
    margin-bottom: 8px;
    margin-left: 10%;
    width: calc(80% - 20px);
    /* width adjusted to fit within the parent container */
    cursor: pointer;
    animation: fadeIn 2.5s ease-out;
}