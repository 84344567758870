.wallet-tag-display {
    background-color: #0e6312;
    /* Green */
    color: #fff;
    padding: 4px 10px;
    border-radius: 6px;
    font-size: 9px;
    font-weight: normal;
    display: inline-block;
    margin-right: 5px;
    margin-left: 8px;
    /* Fixed value instead of percentage */
}