/* Full page overlay that acts as the modal backdrop */
.tge-full-page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.671);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 2rem;
    z-index: 1000;
    flex-direction: column;
    
}

/* The modal box itself */
.tge-coming-soon-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #000000;
    font-size: 15px;
    color: #ffffff;
    width: 45%;
    text-align: center;
    border: 1px solid #212020;
    /* Light grey border */
    
}

/* Logo inside the modal */
.tge-modal-logo {
    max-width: 100px;
    margin-bottom: 20px;
}

/* Button inside the modal */
.tge-modal-content-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    margin-bottom: 35px;
}


.event-date {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    /* Smaller than the countdown timer, adjust as needed */
    color: #FFFFFF;
    /* Adjust as needed */
    margin-top: 10px;
    /* Space between the countdown header and this text */
    text-align: center;
}



/* Optional: Animation to fade in the modal on load */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.tge-full-page-overlay {
    animation: fadeIn 0.5s ease-in-out;
}



/* Add styles for close button */
.tge-close-modal-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: rgb(109, 106, 106);
    font-size: 0.9rem;
    cursor: pointer;
    display: none;
}


.tge-social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    /* Adjust the space between icons as needed */
    margin-bottom: 20px;
    /* Space above the 'Got it!' button */
}


.tge-social-icon {
    width: 20px; /* Consistent size on all devices */
    height: 20px; /* Ensure it's always square */
    cursor: pointer;
    border-radius: 50%;
    padding: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    fill: #ffffff; /* Explicit white color, adjust as needed */
}

.tge-social-icon:hover,
.tge-social-icon:focus {
    background-color: rgba(255, 255, 255, 0.1);
    /* Slight white tint on hover */
    transform: scale(1.1);
    /* Slightly enlarges the icon on hover */
}


/* Style for the countdown header text */
.tge-countdown-header {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    /* Adjust as needed */
    font-weight: bold;
    /* Adjust as needed */
    color: #FFFFFF;
    /* Adjust as needed */
    margin-bottom: 16px;
    /* Adjust as needed */
    text-align: center;
}

/* Style for the countdown timer text */
.tge-countdown-time {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    /* Adjust as needed */
    font-weight: bold;
    /* Adjust as needed */
    color: #FFFFFF;
    /* Adjust as needed */
    text-align: center;
}


/* ExplorerMainPageNotice.css */

.event-description {
    color: #ccc; 
    font-size: 14px; 
    margin: 10px 0 20px; 
    text-align: center; 
    width: 70%;
}


/* Adjusting modal width and padding for smaller screens */
@media (max-width: 768px) {
    .tge-coming-soon-modal {
        width: 85%;
        /* wider in mobile for better readability */
        padding: 10px;
    }

    .tge-countdown-time {
        font-size: 24px;
        /* smaller font size for countdown on mobile */
    }

    .tge-countdown-header,
    .event-date {
        font-size: 16px;
        /* smaller font size for header and date on mobile */
    }
}

/* Logo inside the modal */
.tge-modal-logo {
    max-width: 100px;
    margin-bottom: 20px;
}

/* Button inside the modal */
.tge-modal-content-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    margin-bottom: 35px;
}

/* Close button visible always and adjusted for mobile */
.tge-close-modal-button {
    display: block;
    /* make sure the close button is always visible */
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    color: rgb(109, 106, 106);
    font-size: 1.2rem;
    /* slightly larger close button on mobile for easier access */
    cursor: pointer;
    display: none;
}

.tge-social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.tge-social-icon {
    width: 24px;
    /* Slightly larger icons for better touch targets on mobile */
    height: 24px;
    cursor: pointer;
    border-radius: 50%;
    padding: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    fill: #ffffff;
}

.tge-social-icon:hover,
.tge-social-icon:focus {
    background-color: rgba(255, 255, 255, 0.1);
    transform: scale(1.1);
}

/* Optional: Animation to fade in the modal on load */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


