@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

body {
    font-family: 'Inter', sans-serif; /* Use Inter font for all text in the body */
}




/* AIChatMessageItem.css */
.ai-chat-message-item {
    position: relative; 
    width: auto;
    max-width: 98%; 
    min-height: 100px; 
    background: var(--component-background-color);
    padding: 2%;
    box-sizing: border-box;
    margin-bottom: 5%; 
    border-radius: 10px; 
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    padding-left: 3%;
    font-family: 'Inter', sans-serif;
}


.message-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the start, not center */
    padding-top: 10px; /* Add some padding at the top, adjust as needed */
}

.app-icon {
    position: absolute;
    top: 3%;
    left: 1.5%;
    width: 4%;
    height: auto; /* and this */
}

.app-icon-dark {
    position: absolute;
    top: 3%;
    left: 2%;
    width: 3%;
    height: auto;
    /* and this */
}


.copy-icon {
    /* Adjust as needed */
    position: absolute;
    top: 0px;
    right: 1.5%;
    cursor: pointer;
}

.ai-chat-message-item p {
    padding-top: 1%; /* push the text down, below the icons */
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    
}


.ai-message-text {
    flex-grow: 1;
    margin: 0 35px; /* Only apply horizontal margins */
    overflow-wrap: break-word;
    font-size: 14px;
    padding: 0.7%;
    font-family: 'Inter', sans-serif;
}



/* Style for the container wrapping the success icon and message */
.trade-success-container {
    display: flex;
    justify-content: center;
    /* Center the items horizontally */
    align-items: center;
    /* Center the items vertically */
    gap: 5px;
    /* Optional: Adjusts the space between the icon and message */
    margin-top: 10px;
}

.trade-success-icon {
    /* Adjust the size if necessary */
    width: 24px;
    /* Example size, adjust as needed */
    height: auto;
}

.trade-success-message {
    font-size: 12px;
    /* Example font size, adjust as needed */
    color: var(--text-color);
    /* Example success message color, adjust as needed */
}


/* Assuming this is within TokenLink.css or any relevant CSS file */
.tx-details-link,
.tx-details-link:link,
.tx-details-link:visited,
.tx-details-link:hover,
.tx-details-link:active {
    color: #176ab3;
    /* Sets the link color */
    font-weight: bold;
    /* Makes the text bold */
    text-decoration: none;
    /* Removes underline from links */
    overflow-wrap: break-word;
    /* Ensures the link can wrap within its container */
    display: inline;
    /* Keeps link inline with surrounding text */
    word-break: break-all;
    /* Allows the link to break and wrap at any character */
    font-size: 12px;
    /* Sets the font size */
}



.transaction-rejected-or-error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* Adjust the space between the icon and the text */
    margin-top: 20px;
    /* Adds some space above the message */
   
    
}

.failed-or-rejected-icon {
    width: 24px;
    /* Adjust icon size */
    height: 24px;
    /* Adjust icon size */
}

.transaction-rejected-or-error-message {
    color: #f12121;
    /* This sets the text color to a red shade; adjust as needed */
    font-size: 12px;
    /* Adjust text size */
    align-items: center;
}


