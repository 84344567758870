/* Badge.css */
.badge-container {
    background-color: red;
    color: white;
    padding: 0 6px;
    /* Adjust based on your design */
    font-size: 0.75rem;
    /* Adjust based on your design */
    height: 20px;
    /* Adjust based on your design */
    border-radius: 10px;
    /* Circular badge */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    /* Adjust to position correctly */
    right: 12px;
    /* Adjust to position correctly */
    transform: translate(50%, -50%);
    /* Center the badge on top-right of the icon */
}