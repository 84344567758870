.token-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    /* Ensuring the container takes full height */
    background: #000000;
    /* Matching the dark theme of your cards */
    color: #3d3b3b;
    font-family: 'Inter', sans-serif;
  
}

.token-user-main-content {
    width: 100%;
    max-width: 1900px;
    /* Or the max-width you prefer */

    /* Adjust based on your layout */
}

.token-overview-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    /* Ensures the layout is responsive */
    margin-top: -10vh;
    padding: 12%;
    margin-bottom: -10%;

}

.token-details-chart-container {
    position: relative;
    /* This is required for absolute children */
    /* ... */
}



.tge-copy-icon {
    margin-left: 2px;
    cursor: pointer;
    transition: transform 0.2s ease;
    width: 20px;
        height: auto;
}


.tge-tick-icon {
    margin-left: -6px;
    top: -6px;
    position: absolute;
    transition: transform 0.2s ease;
    width: 30px;
    height: auto;
}




/* Tablet devices (portrait and landscape) */
@media only screen and (max-width: 768px) {

    .token-container {
        justify-content: flex-start;
        /* Align to the start if you stack the children */
        padding: 5%;
        /* Add padding instead of using justify-content: center; */
        min-height: auto;
        /* Adjust the height to be automatic */
    }

    .token-user-main-content {
        max-width: 100%;
        /* Full width of the device */
    }

    .token-overview-container {
        justify-content: flex-start;
        /* Align children at the start */
        margin-top: 0;
        /* Remove negative margin */
        padding: 5%;
        /* Less padding than desktop */
        margin-bottom: 0;
        /* Remove negative margin */
    }

  
}

/* Mobile devices */
@media only screen and (max-width: 480px) {

    .token-container {
        align-items: flex-start;
        /* Align to the start since items might stack vertically */
    }

    /* The .token-user-main-content class will inherit styles from the tablet breakpoint */

    .token-overview-container {
        flex-direction: column;
        /* Stack the token cards vertically */
        align-items: center;
        /* Center-align the flex items for a neat vertical stack */
        padding: 2%;
        /* Even less padding for small screens */
    }


}