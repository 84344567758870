/* ReferLink.css */
.refer-link-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Align items to the start of the container */
    background: var(--component-background-color);
    /* White background */
  
    /* Thin black border */
    border-radius: 10px;

    font-size: 10px;
    color: var(--text-color);
    /* Black text color */
    width: 100%;
    /* Set width to take full container width */
}





.url-and-copy-icon-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* Ensure the container takes full width of the parent */
}

.refer-link-url {
    flex-grow: 1;
    /* Ensure the URL text takes up remaining space */
    margin-right: 10px;
    /* Add some margin between URL text and copy icon */
}

.copy-clipboard-icon {
    cursor: pointer;
    margin-left: 10px;
    /* Space between copy icon and the container edge */
}