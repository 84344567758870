/* ParentTokenDetailsComponent.css */
.token-tx-details {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 89.1%;
    max-width: 100rem;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2%;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    overflow: auto;
    min-height: 20vh;
    top: 2vh;
    right: 7.7vh;
}


/* Set the grid layout for both header and content rows */
.token-header-row,
.token-content-row {
    display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        /* 6 equal columns */
        gap: 10px;
        /* Adjust gap between columns */
        width: 100%;
}

.token-header-row {
    font-weight: bold;
    font-size: 10px;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 0.1rem;
}

.token-content-row {
    font-size: 12px;
        border-bottom: 1px solid #c8c3c351;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 12px;
        align-items: center;
}



.protocol-direction-icon {
    margin-right: 8px;
        width: 18px;
        height: auto;
}










.token-no-data {
    grid-column: 1 / -1;
    text-align: center;
    font-size: 10px;
}

.token-link-container {
    display: flex;
    align-items: center;
    justify-content: start;
}

.token-icon-inline {
    margin-right: 8px;
    width: 18px;
    height: auto;
}

.token-amount-with-logo {
    display: flex;
    align-items: center;
}

.token-tx-panel-token-logo {
    width: 20px;
    border-radius: 50%;
    height: 20px;
    object-fit: contain;
    margin-right: 5px;
}

.token-amount-column {
    display: flex;
    align-items: center;
}

.token-tx-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    width: 100%;

}




/* Media query for mobile devices */
@media (max-width: 768px) {
    .token-tx-details {
        /* Full width for mobile view */
        width: 91%;
        /* Adjust padding for smaller screens */
        padding: 0.5rem;
        /* Center the container */
        margin-left: auto;
        margin-right: auto;
        /* Reduce the margin to fit the screen */
        margin-top: 10px;
        /* Adjust the margin-left to center the component */
        margin-left: 15%;
        
    }

    /* Adjusting grid layout for smaller screens */
    .token-header-row,
    .token-content-row {
        /* Adapt the grid to stack items vertically or show less information in two columns */
        grid-template-columns: repeat(2, 1fr);
        /* Custom adjustments may be needed based on your content */

    }

    .token-content-row {
        /* Adjust font size for readability on small screens */
        font-size: 12px;
    }

    .token-no-data {
        /* Ensure the no-data message spans across both columns */
        grid-column: 1 / -1;
    }

    .protocol-direction-icon,
    .token-icon-inline,
    .token-tx-panel-token-logo {
        /* Adjust icon sizes as necessary for mobile view */
        width: 15px;
        height: auto;

    }




  

    .burn-address {
        /* Allow the burn address to wrap and avoid overflow */
        width: auto;
        white-space: normal;
    }

    .token-tx-loading-container {
        /* Adjust the loading container to fit within smaller screens */
        min-height: 100px;
    }

    /* You might need to adjust other elements or add more specific styles depending on your layout and content */
}
