.notification-settings-modal {
    position: absolute;
    z-index: 10;
   
    top: 22.8%;
    width: 32vh;
    left: 40vw;
    right: 0;
    display: flex;
    flex-direction: column;
    background: var(--component-background-color);
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
    min-height: 280px;
    font-family: 'Inter', sans-serif
}

.notification-modal-header {
    padding: 12px;
    border-bottom: 1px solid #eaeaea;
    /* Styling for the header */
}

.notification-modal-header h2 {
    margin: 0;
    font-size: 14px;
}

.wallet-address-notification-bell-icon {
    width: 20px;
    height: auto;
    margin-left: 5px;
    cursor: pointer;
}

.notification-options {
    display: flex;
    flex-direction: column;
    padding: 12px;
    /* Adjust padding as needed */
}

.notification-option {
    padding: 8px 0;
    font-size: 12px;
    margin-left: 2px;
    
   
}





.notification-option:last-child {
    border-bottom: none;
}






.notification-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    /* Light overlay */
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    /* Match your modal's border radius */
}







.notification-modal-overlay a {
    color: #176ab3;
        font-weight: bold;
        text-decoration: none;
        overflow-wrap: break-word;
        display: inline;
        word-break: break-all;
        
}


.notification-modal-overlay a,
.notification-modal-overlay a:link,
.notification-modal-overlay a:visited,
.notification-modal-overlay a:hover,
.notification-modal-overlay a:active,
.notification-modal-overlay a:focus {
    color: #176ab3;
    /* Consistent color across all link states */
    font-weight: bold;
    text-decoration: none;
    overflow-wrap: break-word;
    display: inline;
    word-break: break-all;
}