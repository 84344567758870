.token-holdings-container {
   
    display: flex;
    flex-direction: column;
    width: 70vh;
    max-width: 100rem;
    margin: auto;
    padding: 10px;
    border-radius: 8px;
    margin-right: 90px;
    margin-left: -12px;
}


.token-holdings-dropdown {
    position: absolute;
    z-index: 10;
    margin-left: 1.6%;
    top: 90%;
    width: 95%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background: var(--component-background-color);
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    max-height: 0;
    overflow: auto;
    transition: max-height 0.4s ease-in-out;
    min-height: 200px;

}





.token-holdings-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    width: 100%;
    cursor: pointer;
    background: #f7f7f7;
    border-radius: 8px;
}




.token-holdings-dropdown.open .token-holdings-content {
    max-height: 500px;
  
}





.token-total-value {
    font-size: 12px;
    font-weight: bold;
    color: #333;
}

.token-count {
    font-size: 12px;
    color: #666;
}

.dropdown-icon {
    font-size: 14px;
    color: #333;
}



.token-holding:last-child {
    border-bottom: none;
}



.token-holding-header,
.token-holding {
    display: grid;
    /* Adjust the fractions to allocate the space according to the content width */
    grid-template-columns: 3fr 3fr 1fr;
    gap: 10px;
    width: 90%;
    margin-left: auto;
    /* Center the grid */
    margin-right: auto;
    /* Center the grid */
}

.token-holding-header {
    font-weight: bold;
    font-size: 9px;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 0.5rem;
    margin-top: 8px;
}

.token-holding {
    font-size: 12px;
    border-bottom: 1px solid #f7f7f7;
    padding: 5px 0;
}

.token-total-value {
    margin-right: 80px;
}





/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .token-holdings-container {
        width: 84%; /* Full width on smaller screens */
        padding: 10px; /* Adjust padding as needed */
        margin-right: 60px; /* Remove right margin */
        margin-left: 0; /* Remove left margin */
        border-radius: 8px; /* Maintain border radius */
    }



    .token-holdings-summary {
        padding: 10px; /* Adjust padding for summary */
        background: #f7f7f7; /* Maintain background */
        border-radius: 8px;
        width: 75vw; /* Maintain border radius */
    }

   

    .token-holding-header {
        font-size: 10px; /* Adjust font size for headers */
        padding-bottom: 5px; /* Adjust bottom padding */
        margin-top: 5px; /* Adjust top margin */
    }

    .token-holding {
        font-size: 11px; /* Adjust font size for items */
        padding: 5px 0; /* Adjust vertical padding */
    }




}