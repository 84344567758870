.token-volume-container {
    display: flex;
        align-items: center;
        justify-content: center;
        /* Add this if you want to center the children */
        background: #000;
        border-radius: 10px;
        padding: 4px 12px;
        font-size: 12px;
        color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        /* Remove margin-left and use justify-content for centering */
        /* margin-left: 8.5%; */
        margin-top: 0px;
        margin-bottom: 2px;
        position: relative;
        left: 10px;
        /* Adjust or remove if necessary */
        margin-left: 25%;
        /* Adjust or remove if necessary */
        /* Add a min-height or height if necessary */
        height: 25px;
        /* Example height, adjust as necessary */

}


.token-volume-divider {
    height: 20px;
    /* Adjust the height as needed */
    width: 1px;
    /* Adjust the width as needed */
    background-color: #ccc;
    /* Adjust the color as needed */
    margin-left: 18px;
    margin-right: 18px;
}

@media (max-width: 576px) {
    .token-volume-container {
        background: none;
        /* No background color for the container */
    }

    .token-volume-container p,
    .token-volume-container p strong {
        font-size: 11px;
        /* Set the font size to 10px for both p and strong */
        line-height: 1.2;
        /* Adjust line height to ensure readability at smaller sizes */
      
        
        
    }
}