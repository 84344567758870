/* BlackfinTokenDetails.css */


.blackfin-token-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 27%;
    max-width: 100rem;
    margin-left: 12.5%;
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    overflow-y: auto;
    /* Enable vertical scrolling */
    height: 25vh;
    margin-bottom: 2rem;
    justify-content: start;
    font-size: 10px;
   
   
}

.token-info-section {
    display: flex;
    /* Use flexbox for layout */
    justify-content: space-between;
    /* Space between the two columns */
    align-items: flex-start;
    /* Align items to the start of the flex container */
    width: 110%;
    
}

.token-info-column {
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    padding-right: 40px;
}



.token-info-subtitle {
    font-family: 'Inter', sans-serif;
   
    /* Adjust font size as needed */
    color: #ccc;
    /* Light grey, or as per your design */
    margin-bottom: -5px;
    /* Space between subtitle and main info */
    font-size: 11px;
}

.token-info-main {
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    /* Adjust font size as needed */
    color: #ffffff;
    /* White, or as per your design */
    /* Add any additional styling such as font weight, etc. */
}



.token-info-subtitle-link {
    font-family: 'Inter', sans-serif;

    /* Adjust font size as needed */
    color: #ccc;
    /* Light grey, or as per your design */
    margin-bottom: 5px;
    /* Space between subtitle and main info */
    font-size: 11px;
}



.token-info-link {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    /* Adjust font size as needed */
    color: #386999;
    /* Use a color that stands out or fits your design */
    text-decoration: none;
    /* Removes underline from links */
    /* Add any additional styles for hover state, etc. */
  
}






@media (max-width: 768px) {
    .blackfin-token-details-container {
        width: 82%;
        /* Full width of the screen */
        max-width: none;
        /* Override the 100rem max-width */
        margin-left: 19px;
        /* Remove the left margin */
        height: auto;
        /* Adjust height to content */
        margin-bottom: 1rem;
        /* Add space between this container and anything below it */
        overflow-y: visible;
        /* Adjust if you want to remove scrolling */
        /* If the container has a title or heading, you might want to adjust its font size for smaller screens */
    }
}