/* TokenTagComponent.css */
.token-tag-display {
    background-color: #0a6dbd;
    color: #fff;
    padding: 4px 10px;
    border-radius: 6px;
    font-size: 9px;
    font-weight: normal;
    display: inline-block;
    margin-right: 5px;
    margin-left: -6.2vw;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {

    /* Adjust for tablets */
    .token-tag-display {
        font-size: 8px;
        /* Slightly smaller font size */
        padding: 3px 8px;
        /* Adjust padding */
        margin-left: -8vw;
        /* Adjust margin to better fit smaller screens */
       
    }
}

@media (max-width: 480px) {

    /* Adjust for mobile phones */
    .token-tag-display {
        font-size: 7px;
        /* Even smaller font size for phones */
        padding: 2px 6px;
        /* Reduce padding */
        margin-left: -10vw;
        /* Increase negative margin for small devices */
        width: 20px;
        
    }
}