@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.txSummaryContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 20px;
    margin-left: 45px;
    width: 100%;
    max-width: 600px;
        
   
}

.txSummaryHeaderText {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--text-color);
}

.txSummaryTextContent {
    font-size: 12px;
    color: var(--text-color);
    word-break: break-word;
    
}

.txSummaryIcon {
    vertical-align: middle;
    margin-right: 8px;
    width: 20px;
    height: auto;
    
}

.txFailText {
    color: #8B0000;
    /* Deep dark red color */
    /* Add other styling as needed */
}






/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .txSummaryContainer {
        margin-top: 19px; /* Adjust top margin */
        margin-left: 5%; /* Reduce left margin to utilize more screen space */
        width: 90%; /* Increase width to use more of the screen */
        max-width: none; /* Remove max-width restriction */
    }

    .txSummaryHeaderText {
        font-size: 11px; /* Slightly reduce font size for header text */
    }

    .txSummaryTextContent {
        font-size: 11px; /* Slightly reduce font size for content text */
    }

    .txSummaryIcon {
        width: 18px; /* Slightly reduce icon size */
        /* Adjust height if necessary */
    }

   
}