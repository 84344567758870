



body {
    font-family: 'Inter', sans-serif; /* This sets Inter font across the app */
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 35px;
    padding: 8px 15px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: rgba(28, 28, 28, 1);
    margin-top: 26px;
    border: 0.5px solid white; /* Add this line to create a thin white border */
}

    .button:hover {
        background-color: #000000;
    }



.text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 20px;
    word-wrap: break-word;
}


.button-green {
    background-color: #296802;
    /* Green color */
    color: white;
    /* Assuming you want white text for contrast */
}


.button-green:hover {
    background-color: #1D4504;
    /* Darker shade of green */
}

.button-red {
    background-color: #9D0404;
    /* Red color */
    color: white;
}

.button-red:hover {
    background-color: #840D0D;
    /* Darker shade of red */
}
