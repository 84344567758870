.explorer-main-notice {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #000;
    border-radius: 10px;
    padding: 4px 12px;
    font-size: 14px;
    /* Might need to adjust for smaller screens */
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 20px 0;
    width: 22%;
    /* This might be too narrow on mobile, consider percentage or viewport width */
    top: 50%;
    /* Adjust for mobile view to avoid clipping or overlap */
    left: 40%;
    /* Centering might need adjustment */
    height: 10%;
    /* Ensure this scales properly */
    padding: 12px;
    

  
}



.notice-header {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 12px;
    top: 10%;
    /* Adjust this value to lower the header slightly */
  
    
   
}

.notice-content {
    padding-left: 30px;
    /* Space for the pin icon */
    font-size: 12px;
    flex-grow: 1;
    /* Content to fill the space */
    margin-top: 5px;
    /* Increased top margin to space away from header */
    margin-bottom: 10px;
    /* Space below the content, if needed */

    text-align: center;
    
    
}

.notice-timer {
    font-size: 18px;
    /* Larger font size for emphasis */
    color: #c8c8c8;
    /* Highlight color for the timer */
    margin-bottom: 15px;
    padding: 10px;
   
}



/* Responsive adjustments for tablets */
@media (max-width: 768px) {
    .explorer-main-notice {
        font-size: 12px;
        /* Smaller text on smaller screens */
        width: 60%;
        /* Wider on small screens to fit content */
        left: 50%;
        /* Adjust centering */
        top: 45%;
        /* Might need to adjust this based on your actual UI elements */
    }
}




/* Responsive adjustments for mobile phones */
@media (max-width: 480px) {
    .explorer-main-notice {
        font-size: 11px;
        /* Even smaller text for mobile */
        width: 62%;
        /* Use more of the screen width */
        left: 18%;
        /* Center horizontally */
        top: 50%;
        /* Adjust vertical position */
    }
}