.wallet-overview {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 40%;
    
    margin-top: 0%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0%;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    overflow: visible;
    height: auto;
    top: 3vh; 
    left: 9vh; 
}

.wallet-overview .overview-title {
    font-family: 'Inter', sans-serif;
        color: var(--text-color);
        font-size: 15px;
        margin-bottom: 20px;
        margin-top: 0px;
}

.wallet-overview .overview-item {
    margin-bottom: 12px;
}

.wallet-overview .overview-label {
        display: block;
        font-family: 'Inter', sans-serif;
        font-size: 10px;
        color: var(--text-color);
        text-transform: uppercase;
        font-weight: bold;
}

.wallet-overview .overview-data {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        color: var(--text-color);
}


.wallet-overview-solana-icon {
    width: 18px;
    /* Adjust the size as needed */
    height: auto;
    /* Adjust the size as needed */
    border-radius: 50%;
    /* Makes the image circular */
    margin-right: 5px;
    /* Adds some space between the icon and the text */
}


.balance-container {
    display: flex;
    align-items: center;
    margin-top: 5px;
}




@media (max-width: 768px) {
    .wallet-overview {
        width: 100%; /* Take full width to utilize available screen space */
        max-width: 92%;
        margin-top: 2vh;
       
        top: 2vh;
        left: auto; /* Reset left positioning for smaller screens */
        padding: 9px; /* Adjust padding for smaller screens */

    }

        .wallet-overview .overview-title,
        .wallet-overview .overview-label,
        .wallet-overview .overview-data {
            font-size: 10.8px; /* Adjust font size for readability on smaller screens */
        }

    .wallet-overview-solana-icon {
        width: 16px; /* Adjust icon size for smaller screens */
    }

    .balance-container {
        margin-top: 2px; /* Adjust margin to reduce spacing on smaller screens */
    }
}

