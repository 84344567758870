.sierra-searchbar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    /* Adjust if necessary */
    width: 30vw;
    /* Adjust based on header layout */
    max-width: 100%;
    /* Example max-width, adjust as necessary */
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 20px;

    /* Removed 'top' and 'left' properties to prevent misplacement */
    left: 10px;

}




.sierra-search-input {
    width: 30vw;
    height: 45px;
    padding-left: 20px;
    /* Adjusted for inside the header */
    border: 1px solid lightgrey;
    border-radius: 20px;
    font-family: 'Inter', sans-serif;
    background: var(--component-background-color);
    color: var(--text-color);
    text-align: center;
}



.sierra-search-input:focus {
    outline: none;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.sierra-search-button {
    padding: 10px 15px;
    margin-left: 10px;
    border-radius: 20px;
    border: none;
    background-color: #007aff;
    /* Example color, adjust as needed */
    color: white;
    cursor: pointer;
}


.sierra-search-dropdown {
    position: absolute;
    overflow: auto;
    /* Enables scrolling within the dropdown */
    top: 90%;

    /* Set a max-height for the dropdown */
    background: var(--component-background-color);
    padding: 0px;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    z-index: 2;
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 93px;
    max-height: 48vh;
    z-index: 11;

}



/* Media query for mobile devices */
@media (max-width: 768px) {


.sierra-searchbar-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        /* Adjust if necessary */
        width: 80vw;
        /* Adjust based on header layout */
        max-width: 100%;
        /* Example max-width, adjust as necessary */
        margin: auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 20px;

        /* Removed 'top' and 'left' properties to prevent misplacement */
        left: -2%;

    }


.sierra-search-input {
        width: 72vw;
        height: 33px;
        padding-left: 20px;
        /* Adjusted for inside the header */
        border: 1px solid lightgrey;
        border-radius: 12px;
        font-family: 'Inter', sans-serif;
        background: var(--component-background-color);
        color: var(--text-color);
        text-align: center;
        margin-right: 7px;
        font-size: 16px;

    }



    .sierra-search-input:focus {
        outline: none;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    }

    .sierra-search-button {
        padding: 10px 15px;
        margin-left: 10px;
        border-radius: 20px;
        border: none;
        background-color: #007aff;
        /* Example color, adjust as needed */
        color: white;
        cursor: pointer;
    }




.sierra-search-dropdown {
    position: absolute;
    overflow: auto;
    /* Enables scrolling within the dropdown */
    top: 90%;

    /* Set a max-height for the dropdown */
    background: var(--component-background-color);
    padding: 0px;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    z-index: 2;
    display: flex;
    flex-direction: column;
    left: 10px;
    width: 115%;
    min-height: 93px;
    max-height: 48vh;

}
    
}