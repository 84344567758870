.wallet-address-page-overview-and-info-container {
    display: flex;
    justify-content: center; 
    align-items: flex-start;
    gap: 1rem; 
    margin-top: 0vh;
    margin-left: auto;
    margin-right: auto;
    width: auto; 
    max-width: 100%; 
    flex-wrap: wrap;
    padding: 0 1rem; /* Add some padding on both sides */
}


