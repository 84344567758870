.explorer-searchbar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 100vh;
    margin: auto;
    top: 45vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* Example shadow effect */
    border-radius: 20px;
    /* Optional: if you want rounded corners */
  
    left: 2vh;
    background: var(--component-background-color);
    
}

.explorer-search-input {
    width: 100%;
    height: 45px;
    padding-left: 40px;
    border: 0.1px solid lightgrey;
    border-radius: 20px;
    font-family: 'Inter', sans-serif;
    background: var(--component-background-color);
    color: var(--text-color);
   
}

.explorer-search-input:focus {
    outline: none;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    
   
  
}

.explorer-search-icon {
    position: absolute;
    left: 5px;
    width: 43px;
    height: auto;
}

.explorer-search-icon-dark {
    position: absolute;
    left: 15px;
    width: 20px;
    height: auto;
}

.explorer-search-dropdown {
    position: absolute;
    overflow: auto;
    /* Enables scrolling within the dropdown */
    top: 90%;
    width: 99vh;
    min-height: 13vh;
    max-height: 48vh;
    /* Set a max-height for the dropdown */
    background: var(--component-background-color);
    padding: 0px;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    z-index: 2;
    display: flex;
    flex-direction: column;
}


/* Add styles for the dropdown items */
.explorer-dropdown-item {
    padding: 10px;
    border-bottom: 1px solid #f8f8f8;
    cursor: pointer;
}

.explorer-dropdown-item:hover {
    background-color: #f8f8f8;
}




/* Add this to your CSS file */
.no-search-results {
    color: var(--text-color);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    text-align: center;
    padding: 10px;
    margin-top: 55px;
}


.defualt-search-message {
    color: var(--text-color);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    text-align: center;
    padding: 10px;
    margin-top: 22px;
}

.address-error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 5px;
}


.recent-searches-section {
    /* Styling for the container of the recent searches */
    padding: 10px;
}

.recent-search-item {
    /* Styling for each recent search item */
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    padding: 2px;
    display: flex;
    margin: 5px 0;

    &:hover {
        background-color: #f0f0f0;
    }
}


.recent-search-item {
    /* other styles */
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
    display: block;
    white-space: nowrap;
    margin-bottom: 5px;
    border-radius: 8px;
    

}



.recent-searches-title {
    color: var(--text-color);
    /* Dark grey */
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 5px;
    padding: 5px;
}


.recent-search-tx-label {
    background-color: #000;
    color: var(--text-color);
    padding: 4px 10px;
    border-radius: 6px;
    font-size: 9px;
    font-weight: normal;
    display: inline-block;
    /* or 'block' if you want it to take up its own line */
    margin-right: 10px;
}


.recent-search-wallet-label {
    background-color: #0e6312;
    /* Green */
    color: var(--text-color);
    padding: 4px 10px;
    border-radius: 6px;
    font-size: 9px;
    font-weight: normal;
    display: inline-block;
    margin-right: 10px;
}

.recent-search-token-label {
    background-color: #0a6dbd;
    /* Blue */
    color: var(--text-color);
    padding: 4px 10px;
    border-radius: 6px;
    font-size: 9px;
    font-weight: normal;
    display: inline-block;
    margin-right: 10px;
}


.recent-search-remove-btn {
    margin-left: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000000;
    /* Or any color that fits your design */
    font-weight: normal;
}


/* Responsive styles */
@media (max-width: 768px) {
    .explorer-searchbar-container {
        max-width: 100%; /* Slightly less than full width for some padding */
        top: 20vh; /* Position higher on mobile screens */
        left: 50%; /* Center horizontally */
        transform: translateX(-50%); /* Adjust for center alignment */
        margin-top: 0; /* Reset top margin */
    }

    .explorer-search-input {
        height: 40px; /* Slightly smaller input for mobile screens */
        padding-left: 20px; /* Less padding on the left */
        font-size: 16px;
    }

    .explorer-search-icon {
        width: 30px; /* Smaller icon on mobile screens */
        left: 10px; /* Adjust left position */
        display: none;
    }

    .explorer-search-dropdown {
        width: 100%; /* Full width dropdown */
        top: 100%; /* Position right below the input */
        max-height: 40vh; /* Adjust max-height to fit mobile screens */
    }

    /* Adjust dropdown item styles for mobile screens */
    .explorer-dropdown-item {
        padding: 8px; /* Slightly less padding */
    }

    /* ... additional responsive adjustments ... */

    /* Simplify recent search styles for mobile screens */
    .recent-search-item {
        font-size: 12px; /* Slightly larger font size for readability */
        padding: 5px; /* More padding */
        margin: 3px 0; /* Less margin between items */
    }

    /* Ensure recent search labels are readable and not too large */
    .recent-search-tx-label,
    .recent-search-wallet-label,
    .recent-search-token-label {
        padding: 3px 8px; /* Less padding */
        font-size: 8px; /* Smaller font size */
    }

    /* Adjust the recent search remove button for easier touch access */
    .recent-search-remove-btn {
        margin-left: 5px; /* Less margin */
        width: 24px; /* Larger touch target */
        height: 24px; /* Larger touch target */
    }

        .explorer-search-icon-dark {
           display: none;
        }



}


