.token-right-side-panel {
    position: absolute;
    top: 25.4%;
    left: 72.8%;
    width: 24%;
    max-width: 100rem;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    height: 70.5vh;
    overflow: auto;
}

.token-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.token-name {
    font-size: 20px;
    margin: 0;
    margin-right: 25%;
    
    /* Remove default margins */
}

.token-panel-buttons {
    display: flex;
}

.buy-button,
.sell-button {
    margin-left: 0.5rem;
    margin-top: -2px;
    /* Space between buttons */
}

/* Additional styles for the content inside the panel */
.token-right-side-panel p {
    font-size: 1rem;
    margin-bottom: 0.25rem;
}

token-right-side-panel a {
    color: #007bff;
    /* Example color for links */
    text-decoration: none;
}

.token-right-side-panel a:hover {
    text-decoration: underline;
}

.token-right-side-panel .token-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 0px;
    
}
