.main-notification-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
}

.main-notification-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    color: #333;
    font-size: 12px;
}

.main-notification-modal-close-button {
    position: absolute;
    right: 16px;
    /* Adjust as needed */
    top: 16px;
    /* Adjust as needed */
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 20px;
    /* Adjust as needed */
    color: #333;
    /* Adjust color to ensure visibility */
}



.notification-item {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
}

.no-notifications {
    text-align: center;
    padding: 20px;
    color: #333;
}




.notification-header-row,
.notification-content-row {
    display: grid;
    grid-template-columns: 100px 1fr 133px auto;
    /* Adjust the sizes to fit your content */
    gap: 10px;
    align-items: center;
    width: 100%;
}

.notification-header-row span,
.notification-content-row span {
    padding: 10px;
    text-align: left;
}

.notification-header-row {
    font-weight: bold;
    border-bottom: 2px solid #eee;
    margin-bottom: 10px;
    font-size: 10px;
}

.notification-content-row {
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
        border-bottom: none;
    }
}


.notification-header-time {
    justify-self: right;
    
    padding-right: 10px;
    /* Adjust padding if necessary to align with content */
}



.notification-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    /* Add padding to create space between header and list */
}

.notification-clear-all {
    font-family: 'Inter', sans-serif;
    /* Use Inter font */
    font-weight: bold;
    /* Make text bold */
    color: rgb(176, 11, 11);
    /* Set the text color to dark red */
    background: none;
    /* No background for the button */
    border: none;
    /* No border for the button */
    cursor: pointer;
    /* Change cursor to pointer to indicate clickable */
    font-size: 12px;
    /* Adjust font size as needed */
    padding: 5px 10px;
    /* Padding inside the button */
}