.refer-table-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 95%;
    max-width: 100rem;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2%;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: var(--component-background-color);
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 55vh; /* Set a max-height for the table container */
    margin-top: 10px;
}




  


/* Custom scrollbar styles */
.refer-table-container::-webkit-scrollbar {
    width: 3px;
    background: var(--component-background-color);
   
}

.refer-table-container::-webkit-scrollbar-thumb {
    background: var(--component-background-color);
    border-radius: 3px;
    
}



.refer-table-header-row,
.refer-table-content-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* Adjusted for 5 columns */
    gap: 10px;
    width: 100%;
}

.refer-table-header-row {
    font-weight: bold;
    font-size: 0.625rem;
    /* Adjusted to 10px */
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 0.5rem;
}

.refer-table-content-row {
    font-size: 0.75rem;
    /* Adjusted to 12px */
    border-bottom: 1px solid #bcbcbc5c;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 12px;
    align-items: center;
}


.refer-table-content-row.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100px;
    /* Adjust the height as necessary */
    flex-direction: column;
}

/* Make sure the prompt doesn't overflow the table cell */
.wallet-connect-prompt {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}


