.latest-trades-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 65%;
    max-width: 100rem;
    margin-top: -1.5%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2%;
    border-radius: 8px;
    box-shadow: 0px 6px 50px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: auto;
    height: 22vh;
    left: -11.5%;
}

.trade-item {
    display: flex;
    width: 100%; /* Ensure the trade item takes full width */
    padding: 5px 10px;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    color: #747474;
}

.trade-header {
    border-bottom: 1px solid #ddd;
}

.trade-item span {
    flex: 1;
    text-align: center;
    min-width: 0; /* Prevents the text from overflowing */
}

/* Consider adding a media query if layout breaks on smaller screens */
@media (max-width: 600px) {
    .trade-item span {
        font-size: 8px; /* Adjust font size for smaller screens */
    }
}