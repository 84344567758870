

/* Base theme colors */
:root {
    --general-background-color: #ffffff;
    --component-background-color: #ffffff;
    --icon-color: #000000;
    /* Solid white for general use */
    --text-color: black;
    --border-color: rgba(255, 255, 255, 0.3);
    /* Light border */

    /* Header specific */
    --header-background-color: rgba(255, 255, 255, 0.117);
    /* Semi-transparent white */
    --header-border-color: rgba(255, 255, 255, 0.3);
    /* Light border, can adjust if needed */
    
}

/* Dark theme colors */
[data-theme='dark'] {
    --general-background-color: black;
    --component-background-color: #1c1b1b;
    --icon-color: #FFFFFF;
    /* Solid dark for general use */
    --text-color: white;
    --border-color: rgba(255, 255, 255, 0.137);
    /* Darker border */

    /* Header specific */
    --header-background-color: rgba(0, 0, 0, 0.711);
    /* Semi-transparent black */
    --header-border-color: rgba(79, 79, 79, 0.628);
    /* Darker border, can adjust if needed */

}



[data-theme='dark'] .assetAmount,
[data-theme='dark'] .highlight-incoming-amount {
    color: #000000;
    /* Override the text color to black in dark mode */
}



