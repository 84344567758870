.header-menu {
    height: 80px;
    background-color: var(--header-background-color);
    /* Uses header-specific variable */
    backdrop-filter: blur(10px);
    color: var(--text-color);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    z-index: 10;
    border-bottom: 1px solid var(--border-color);
    /* Uses general border color variable */
}

.theme-toggle-button {
    cursor: pointer;
    padding: 8px 16px;
    margin-left: auto;
    /* To align it to the right side of the header */
    background-color: var(--primary-color);
    /* Using a CSS variable for the background */
    color: var(--text-color);
    /* Using a CSS variable for the text color */
    border: none;
   
}



/* Adjust the search bar container to fit within the header and align items */
.search-bar-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 33%;
    bottom: 20px;

    
}

.header-item {
    margin-right: 20px; /* Spacing between header items */
    cursor: pointer;
    color: black;
}

.wallet-button-container {
    right: 6%; 
    position: absolute;
}

/* Add more styling here as needed */
/* New style for the search bar container */
.search-bar-container {
    flex-grow: 1; 
    display: flex;
    justify-content: center; 
    margin: 0 20px; 
}



/* Overriding styles for the search bar when used in the header */
.header-menu .explorer-searchbar-container {
    width: 39vw;
    margin: 0 auto;
    top: 0;
    margin-top: 20px;
    left: 100px;

}



.header-search-bar .explorer-searchbar-input {
    width: 100%;
    max-width: 100vh;
    margin: auto;
    top: 32vh;
}



.header-search-bar .explorer-search-dropdown {
        width: 100%;
        min-height: 93px;
        max-height: 48vh;
        
    }










/* HeaderMenu.css */

/* Style the button used to trigger the wallet adapter dropdown */
.wallet-adapter-button-trigger {
    background-color: #000 !important;
    /* Black background */
    color: #fff !important;
    /* White text */
    border: none !important;
    /* No border */
    border-radius: 10px !important;
    /* Rounded corners */
    padding: 4px 20px !important;
    
    /* Reduced padding for a slimmer button */
    font-size: 16px !important;
    /* Keeping font-size as is */
    font-family: 'Inter', sans-serif !important;
    /* Use the 'Inter' font */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    /* Subtle shadow for depth */
    display: flex !important;
    /* Flexbox to center content */
    justify-content: center !important;
    /* Center horizontally */
    align-items: center !important;
    /* Center vertically */
    margin-top: 0px !important;
    /* Keep the margin at the top as is */
}

/* If the button contains an icon before the text, you might need to target it as well */
.wallet-adapter-button-trigger .wallet-adapter-button-start-icon {
    width: 16px !important;
    /* Slightly reduced icon size for slimmer appearance */
    height: 16px !important;
    /* Slightly reduced icon size for slimmer appearance */
}


.desktop-wallet-button {
    display: block;
}

.mobile-wallet-button {
    display: none;
}






/* Media query for mobile devices */
@media (max-width: 768px) {


    .theme-toggle-button {
            display: none;
    
        }

                        .desktop-wallet-button {
                            display: none;
                        }
            
                        .mobile-wallet-button {
                            display: block;
                        }






    .header-menu {
        height: 60px; /* Reduced height */
        background-color: rgba(255, 255, 255, 0); /* Less transparency for readability */
        backdrop-filter: none; /* Remove blur effect */
        padding: 0 1rem; /* Adjust padding */
        border-bottom: none; /* Remove border */
    }

    .search-bar-container {
        right: 10%; /* Adjust right position to keep the search bar within the viewport */
        bottom: 10px; /* Adjust bottom position for alignment */
        flex-grow: 0; /* Prevent the search bar from growing */


    }

    .header-item {
        display: none; /* Hide header items */
    }


    /* Adjust the search input field size to fit within the header */
    .header-menu .explorer-searchbar-container,
    .header-search-bar .explorer-searchbar-input {
        width: 88vw; /* Full width */
       
        margin-top: 0; /* Reset margin top */
        left: 59%; /* Align to the left */
        top: 0; /* Align to the top */
    }

    .header-search-bar .explorer-search-dropdown {
        width: 100%; /* Full width for dropdown */
        max-height: 40vh; /* Adjust max height for better view */
        top: 45px; /* Position below the header */
    }

    /* Hide Solana price if it is within a specific class or ID */
    .solana-price-container {
        display: none; /* Hide Solana price container */
    }

        
}
