/* TokenDataButtonNavigation.css */
.token-data-navigation {
    text-align: left;
    margin-top: 20px;
    
}

.token-data-navigation button {
    display: inline-block;
    font-size: 1rem;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 5px;
}

.token-data-navigation button:hover {
    background-color: #010c47;
    
   
}


.token-data-navigation button.active {
    background-color: #010c47;
    /* Black background for active buttons */
    color: white;
 
}