

/* UserChatMessageItem.css - styling for the container that contains the text*/

body {
    font-family: 'Inter', sans-serif; /* Use Inter font for all text in the body */
}


.user-chat-message-item {
    position: relative;
    width: auto;
    max-width: 98%;
    min-height: 100px;
background: var(--component-background-color);
    padding: 2%;
    box-sizing: border-box;
    margin-bottom: 5%;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    padding-left: 3%;
}




.user-message-text {
    flex-grow: 1;
    margin: 0 35px;
    overflow-wrap: break-word;
    font-size: 14px;
    padding: 0.7%;
    color: var(--text-color);
}




.user-message-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.user-avatar-icon {
    position: absolute;
    top: 3%;
    left: 1%;
    width: 6%;
    height: auto;
    border-radius: 50%;
    padding: 1%;
}





.user-chat-message-item p {
    padding-top: 2%;
}


.bold-text {
    font-weight: bold;
}



.user-avatar-icon {
    width: 20px;
    /* Reduce the size */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 50%;
    /* Make it round */
    overflow: hidden;
    /* This will keep the SVG within the bounds of the circle */
}


.copy-icon-light {
    position: absolute;
    top: 0px;
    right: 1.5%;
    cursor: pointer;
  
    /* Example to fill SVG icon for light mode */
}

.copy-icon-dark {
    position: absolute;
    top: 8px;
    right: 2.2%;
    cursor: pointer;
   
    /* Example to fill SVG icon for dark mode */
}

.tick-icon-user-message {
   width: 30px;
   height: auto;

    /* Example to fill SVG icon for dark mode */
}



