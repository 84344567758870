/* ChatPanel.css */
.chat-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* This will keep the message list at the top and the input box at the bottom */
    height: 100%;
    width: 100%;
    /* It should take the full width of its parent container */
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    /* Allows for scrolling of the message list */
    background-color: transparent;
    position: relative;
   
}


.expanded-chat-panel {
    width: 100%;
    /* Adjust the width as needed for the expanded state */
    margin: 0 auto;
    /* Center the chat panel */
    max-width: 700px;
    /* Set a max-width if desired */
}



.wallet-connect-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%; /* Adjust based on design */
    text-align: center;
    font-size: 16px;
    color: white; /* Adjust based on your design */
    z-index: 2; /* Ensure it appears above blurred content */
    pointer-events: none; /* Optional: Prevent interaction with this overlay */
}

.no-wallet-blur-effect {
    filter: blur(4px);
}


/* For tablet devices */
@media (max-width: 768px) {
    .chat-panel {
        width: 60%;
        max-width: none; /* Resets max-width for smaller devices */
    }
}

/* For mobile and smaller screens */
@media (max-width: 480px) {
    .chat-panel {
        width: 100%;
        padding-bottom: 100px;
    }
}
